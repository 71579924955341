import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";

import { useFormik } from "formik";
import { toast } from "react-hot-toast";

// Services
import { createAccessPass, getAccessPassByWorkspaceId, getAllAccessPassses } from "../../services/apis/access_pass";
import { updateAccessPassUserList } from '../../services/apis/access_pass_user';
import { createUserAdmin, getAllUsers } from "../../services/apis/user";
import { createWorkspace, getAllWorkspaces, updateWorkspace } from "../../services/apis/workspaces";

// Components
import DashboardSidebar from "../../components/dashboard/DashboardSidebar";
import ToastModal from "../../components/shared/ToastModal";
import Dropdown from "../../layout/shared/Dropdown";

// Methods
import { getRole, getUser } from "../../common/methods/storage";

// Constants
import { roles } from "../../constants/lists/role";
import { MESSAGES } from "../../constants/response_message";

// Images
import btnArrowDownSection from "../../assets/images/buttons/btn-arrow-down-section.svg";
import btnArrowUpSection from "../../assets/images/buttons/btn-arrow-up-section.svg";
import btnExport from "../../assets/images/buttons/btn-export.svg";
import iconCancel from '../../assets/images/icons/icon-cancel.svg';
import iconLogo from '../../assets/images/icons/icon-logo.svg';
import iconSearch from "../../assets/images/icons/icon-search.svg";
import iconSelector from "../../assets/images/icons/icon-selector.svg";

const AccessSuperadmin = () => {

  const user = getUser()
  const role = getRole()

  const [workspaces, setWorkspaces] = useState<any[]>([]);
  const [groups, setGroups] = useState<any[]>([]);
  const [users, setUsers] = useState<any[]>([]);
  const [groupsByWorkspace, setGroupsByWorkspace] = useState<any[]>([]);
  const [groupsByName, setGroupsByName] = useState<any[]>([]);

  const [formikWorkspace, setFormikWorkspace] = useState<any>("");
  const [clearFormikWorkspace, setClearFormikWorkspace] = useState<boolean>(false)
  const [selectedImage, setSelectedImage] = useState<any>(null);
  const [imageName, setImageName] = useState<string | null>(null)

  const [formikAdminGroupAccess, setFormikAdminGroupAccess] = useState<any[]>([]);
  const [clearFormikGroupAccess, setClearFormikGroupAccess] = useState<boolean>(false);
  const [formikCompany, setFormikCompany] = useState<any>("");
  const [clearFormikCompany, setClearFormikCompany] = useState<boolean>(false)

  const [formikAdminUser, setFormikAdminUser] = useState<any>("");
  const [formikWorkspaceAccess, setFormikWorkspaceAccess] = useState<any>('')
  const [formikGroupAccessPermission, setFormikGroupAccessPermission] = useState<any[]>([])
  const [clearFormikGroupAccessPermission, setClearFormikGroupAccessPermission] = useState<boolean>(false)
  const [clearFormikAdminUser, setClearFormikAdminUser] = useState<boolean>(false)

  const [showAccessSection, setShowAccessSection] = useState<boolean>(false);
  const [showUserSection, setShowUserSection] = useState<boolean>(false);
  const [showPermisionSection, setShowPermisionSection] = useState<boolean>(false);

  const [showModalLink, setShowModalLink] = useState<boolean>(false)
  const [groupLink, setGroupLink] = useState<string>('')

  const abortControllerRef = useRef<AbortController>(new AbortController())

  const fileInputRef = useRef<HTMLInputElement>(null)

  const handleButtonClick = () => {
    fileInputRef.current && fileInputRef.current.click();
  };

  const getWorkspaces = async () => {
    await getAllWorkspaces().then((data) => {
      if (data.status) setWorkspaces(data.data);
    });
  };

  const getGroups = async () => {
    await getAllAccessPassses().then((data) => {
      if (data.status) setGroups(data.groups);
    });
  };

  const getUsers = async () => {
    await getAllUsers("ADMIN").then((data) => {
      if (data.status) setUsers(data.data.map((dt: any) => ({ ...dt, fullname: dt.name + ' ' + dt.last_name })));
    });
  };

  const getGroupsByWorkspaceId = async (id: string, for_to: string) => {
    if (id !== "" || (id == "" && groupsByName.length)) {
      abortControllerRef.current = new AbortController();
      let signal = abortControllerRef.current.signal;

      await getAccessPassByWorkspaceId(id, signal).then((data) => {
        if (data.status) {
          if (for_to === 'ADMIN_CREATE') setGroupsByName(data.groups ?? []);
          else setGroupsByWorkspace(data.groups ?? [])
        }
      });
    }
  };

  const handleImageChange = (event: any) => {
    if (event.target?.files?.length > 0) {
      const selectedFile = event.target.files[0];
      if (selectedFile.size > 128000) {
        console.log('limite')
        toast.error('La imagen supera el limite de 100KB', { position: 'top-center' })
        return;
      }
      const allowedTypes = ["image/jpeg", "image/png", "image/svg+xml"];
      if (!allowedTypes.includes(selectedFile.type)) {
        toast.error("El formato del archivo debe ser JPG, PNG o SVG", { position: 'top-center' })
        return;
      }
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      setImageName(selectedFile.name);
      reader.onload = () => setSelectedImage(reader.result);
    }
  };

  const validateSchema = Yup.object().shape({
    name: Yup.string().required(MESSAGES.ACCESSPASS.CREATE.WRONG.EMPTY),
  });

  const groupForm = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validateSchema,
    initialValues: {
      name: "",
      workspace_id: ""
    },
    onSubmit: async (values) => {
      toast.dismiss()
      let _workspace = formikWorkspace
      const workspaceType = formikWorkspace?.type;

      if (formikWorkspace?.id) {
        values.workspace_id = formikWorkspace?.id;
        await updateWorkspace(values.workspace_id, formikWorkspace.title, selectedImage, formikWorkspace.institution_id)
          .then(data => {
            if (data) {
              setImageName('')
              toast.success('Empresa actualizada', { position: 'top-center' })
            }
        })
      }
      else {
        await createWorkspace(formikWorkspace.title, selectedImage, (workspaceType === 'institution' ? formikWorkspace.institution_id : null))
          .then(data => {
            if (data.status) {
              values.workspace_id = data.data.id
              _workspace = data.data
              setWorkspaces([...workspaces, _workspace])
              setImageName('')
            }
          })
      }

      if (values.workspace_id.length > 0) {
        await createAccessPass(values)
          .then((data) => {
            if (data.status) {
              setClearFormikWorkspace(true)
              setGroupLink(process.env.REACT_APP_WEB_URL + _workspace.domain + '/' + encodeURI(data.data.name))
              setFormikWorkspace("");
              groupForm.resetForm();
              toast.success('Grupo de acceso creado', { position: 'top-center' })
              setTimeout(() => {
                setClearFormikWorkspace(false)
                setShowModalLink(true)
              }, 500);
            }
            else toast.success(data.error.message, { position: 'top-center' })
          })
      }
      else toast.error('El grupo ya existe', { position: 'top-center' })
    },
    validate: (values) => {
      toast.dismiss();
      validateSchema.validate(values).catch((err) => {
        const error: string = err.errors[0];
        toast.error(error, { position: 'top-center' })
      });
    },
  });

  const adminValidateSchema = Yup.object().shape({
    name: Yup.string().required(MESSAGES.ACCESSPASS.CREATE.WRONG.EMPTY),
    email: Yup.string().required(MESSAGES.REGISTER.WRONG.EMPTY).email(MESSAGES.REGISTER.WRONG.EMAIL.PATH),
    password: Yup.string().required(MESSAGES.REGISTER.WRONG.EMPTY),
    repeat_password: Yup.string()
      .required(MESSAGES.REGISTER.WRONG.EMPTY)
      .oneOf([Yup.ref("password"), MESSAGES.REGISTER.WRONG.PASSWORD_MATCH])
  })

  const adminFormik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: adminValidateSchema,
    initialValues: {
      name: "",
      email: "",
      password: "",
      repeat_password: "",
      workspace_id: "",
      role: "",
      groups: [{ name: "" }],
    },
    onSubmit: async (values) => {
      toast.dismiss()

      if (!formikAdminGroupAccess.length) toast.error('Debe seleccionar algún grupo de acceso', { position: 'top-center' })

      values.role = "ADMIN";
      values.groups = formikAdminGroupAccess.map((group) => ({ name: group.name }))
      values.workspace_id = formikCompany.id

      if (formikAdminGroupAccess.length) {
        await createUserAdmin(values)
          .then(async (data) => {
            if (data.status) {
              setClearFormikGroupAccess(true);
              setClearFormikCompany(true)
              setGroupsByName([]);
              setFormikAdminGroupAccess([]);
              setFormikCompany('')
              await getUsers();
              toast.success('Administrador creado', { position: 'top-center' })
              adminFormik.resetForm();
              setTimeout(() => {
                setClearFormikGroupAccess(false);
                setClearFormikCompany(false)
              }, 500);
            }
            else toast.success(data.error.message, { position: 'top-center' })
          })
          .catch((err) => console.log(err))
      }
    },
    validate: (values) => {
      toast.dismiss();
      adminValidateSchema.validate(values).catch((err) => {
        const error: string = err.errors[0];
        toast.error(error, { position: 'top-center' })
      });
    },
  });

  const adminAccessFormik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      user_id: "",
      roles: [{ id: "" }],
      groups: [{ id: "" }],
    },
    onSubmit: async (values) => {
      toast.dismiss()

      if (!formikAdminUser?.id) toast.error('Debe seleccionar un usuario', { position: 'top-center' })
      else if (!formikAdminGroupAccess.length) toast.error('Debe seleccionar algún rol', { position: 'top-center' })

      if (formikAdminGroupAccess.length && formikAdminUser?.id) {
        values.roles = formikAdminGroupAccess.map((role) => ({ id: role.id }))
        values.groups = formikGroupAccessPermission.map((group) => ({ id: group.id }))
        values.user_id = formikAdminUser.id
        await updateAccessPassUserList(values)
          .then((data) => {
            if (data.status) {
              setClearFormikGroupAccessPermission(true);
              setClearFormikWorkspace(true);
              setClearFormikAdminUser(true);
              setClearFormikGroupAccess(true)
              setFormikAdminUser("");
              setFormikGroupAccessPermission([]);
              setFormikAdminGroupAccess([])
              setFormikWorkspaceAccess('');
              toast.success('Accesso actualizados', { position: 'top-center' })
              adminAccessFormik.resetForm();
              setTimeout(() => {
                setClearFormikGroupAccessPermission(false);
                setClearFormikWorkspace(false);
                setClearFormikAdminUser(false)
                setClearFormikGroupAccess(false)
              }, 500);
            }
          })
          .catch((err) => console.log(err))
      }
    },
  });

  useEffect(() => {
    getWorkspaces();
    getGroups();
    getUsers();
  }, []);

  useEffect(() => {
    setClearFormikGroupAccess(true)
    setTimeout(() => {
      setClearFormikGroupAccess(false)
      getGroupsByWorkspaceId(formikCompany.id, 'ADMIN_CREATE');
    }, 500);
  }, [formikCompany]);

  useEffect(() => {
    setClearFormikGroupAccessPermission(true)
    setTimeout(() => {
      setClearFormikGroupAccessPermission(false)
      getGroupsByWorkspaceId(formikWorkspaceAccess.id, 'ADMIN_ACCESS');
    }, 500);
  }, [formikWorkspaceAccess]);

  const openLink = () => window.open(groupLink, '_blank')

  const validateAdminAccess = () => {
    if (formikAdminGroupAccess.length > 0) {
      if (formikAdminGroupAccess.map((agp: any) => agp.id).includes(roles[0].id)) return false;
      else return !formikWorkspaceAccess?.id || formikGroupAccessPermission.length === 0
    }
    return true
  }

  return (
    <section id="dashboard">
      <div className="h-screen w-screen overflow-hidden">
        <ToastModal></ToastModal>
        {
          showModalLink &&
          <div className="fixed inset-0 z-[150] grid place-content-center bg-gray-900/80">
            <div className="h-[200px] w-[680px] bg-[#051d3e] rounded-md shadow-lg p-6 grid grid-cols-1 place-content-center justify-start relative border-[#07DBCB] border-[1px] border-solid">
              <button onClick={() => setShowModalLink(false)} className="absolute right-[20px] top-[10px] h-[35px] w-[35px] text-[0px] bg-contain bg-no-repeat  after:h-[35px] after:w-[35px]  after:absolute after:top-0 after:left-[5px] after:rounded-md after:z-[-1] opacity-80 hover:opacity-100 hover:scale-110" style={{ backgroundImage: `url(${iconCancel})` }}>Cancelar</button>
              <span className="ff-cp--bold text-[20px] text-white">Enlace: </span>
              <div className="text-center w-full ff-cp--light text-[24px] opacity-80 text-white cursor-pointer hover:underline hover:opacity-100 break-words"
                onClick={openLink}>
                {groupLink}
              </div>
            </div>
          </div>
        }

        <div className="h-full w-full p-[24px] grid grid-cols-[44px_auto] grid-rows-1 gap-[24px]">
          <div className="col-[1_/_2] row-1">
            <DashboardSidebar current={1} role={role} />
          </div>
          <div className={`col-[2_/_2] min-h-full min-w-full row-1 gap-7 h-full grid overflow-auto py-[15px] ${!showAccessSection && !showPermisionSection && !showUserSection ? 'grid-rows-[repeat(7,minmax(0,1fr))]' : ''}`}>
            <div className="col-span-1 row-[1_/_2] w-3/5 h-[68px]">
              <div className="relative h-full border-[#60DFFF] bg-gradient-to-b from-[#051d3e] border-2 rounded-[20px]">
                <span className="absolute top-[-16px] left-[24px] w-[160px] h-[32px] bg-[#07DBCB] text-[14px] text-[#000000] rounded-[20px] p-[6px] pl-[14px] leading-normal ff-cp--bold">Administrador</span>
                <div className="h-[64px] pl-[38px] pr-[24px] pt-[16px] pb-[9px] flex justify-between items-center rounded-t-[20px] bg-gradient-to-b from-[#043658] to-transparent">
                  <h2 className="ff-cp--bold text-[#ffffff] text-[24px]">M.O.V.E Match</h2>
                  <div className="flex items-center">
                    <h3 className="ff-cp--bold text-[16px] text-[#07DACB] leading-none mr-[32px]">{user?.nickname}</h3>
                    <img src={iconLogo} alt="" className="w-[34px] h-[34px]" />
                  </div>
                </div>
              </div>
            </div>
            <div className={`col-span-1 ${showAccessSection ? 'row-span-2' : 'row-span-1 my-4'} min-w-full`}>
              <div className={`${showAccessSection ? 'h-full border-2' : 'h-0 border-[1px]'} relative border-[#60DFFF] rounded-[20px]`}>
                <div className="relative h-full rounded-[20px]">
                  <span className="absolute top-[-16px] left-[25px] h-[32px] bg-[#07DBCB] text-[14px] text-[#000000] rounded-[20px] p-[6px] pl-[14px] pr-[14px] leading-normal ff-cp--bold z-20">
                    Creación de GRUPO DE ACCESO
                  </span>
                  <div onClick={() => setShowAccessSection(!showAccessSection)} className="w-[32px] h-[32px] absolute -top-[16px] left-[275px] bg-cover bg-center bg-no-repeat cursor-pointer hover:scale-110" style={{ backgroundImage: `url(${showAccessSection ? btnArrowUpSection : btnArrowDownSection})` }}></div>
                  {
                    showAccessSection &&
                    <div className="overflow-hidden h-full pt-[43px] px-[30px] pb-[24px] rounded-[20px] bg-gradient-to-b from-[#051d3e]">
                      <p className="text-[13px] text-white leading-none ff-cp--regular mb-[40px]">
                        En esta sección se puede crear un <span className="ff-cp--bold">nuevo grupo de acceso</span> para poder administrar la data que brindan los participantes que utilizaron M.O.V.E Match.
                      </p>
                      <form onSubmit={groupForm.handleSubmit}>
                        <div className="grid grid-cols-2">
                          <div>
                            <div className="grid grid-cols-[.35fr_.65fr] gap-3 items-center mb-[20px]">
                              <p className="text-white text-[16px] min-w-[115px] ff-cp--bold">
                                Nombre de empresa
                              </p>
                              <div className="relative bg-[#073147] min-w-[210px] max-w-[310px] h-[32px] flex items-center ff-cp--bold text-[12px] text-[#07DACB] border-[1.5px] border-[#07DACB] rounded-[10px]">
                                <img
                                  src={iconSearch}
                                  alt=""
                                  className="w-[19px] h-[19px] absolute top-0 bottom-0 right-[7px] my-auto"
                                />
                                <Dropdown
                                  items={workspaces}
                                  attribute="title"
                                  callback={setFormikWorkspace}
                                  placeholder="Escribe nombre de empresa"
                                  name="workspace_id"
                                  allowAdd
                                  clear={clearFormikWorkspace}
                                ></Dropdown>
                              </div>
                            </div>
                            <div className="grid grid-cols-[.35fr_.65fr] gap-3 items-center mb-[14px]">
                              <p className="text-white text-[16px] min-w-[115px] ff-cp--bold">
                                Nombre de grupo
                              </p>
                              <input
                                id="name"
                                name="name"
                                disabled={formikWorkspace === ""}
                                value={groupForm.values.name}
                                onChange={groupForm.handleChange}
                                type="text"
                                className="min-w-[210px] max-w-[310px] h-[32px] bg-[#070625] pl-[16px] text-[#ffffff] ff-cp--regular text-[12px] border-[1.5px] border-[#FFFFFF] rounded-[10px]"
                                placeholder="Escribe el nombre aquí"
                              />
                            </div>
                          </div>
                          <div className="w-full grid justify-items-end">
                            <div className={`w-full max-w-[500px] grid grid-cols-[.4fr_.6fr] gap-3 items-center mb-[20px] ${!formikWorkspace?.title || (formikWorkspace?.id && formikWorkspace?.title) ? 'opacity-70' : 'opacity-100'}`}>
                              <p className="text-white text-[16px] min-w-[115px] ff-cp--bold">
                                Logo de empresa
                              </p>
                              <div className="w-full grid grid-rows-2 gap-3 px-1">
                                <div className="w-full grid grid-cols-[auto_100px] justify-end gap-8">
                                  <div className="w-full">
                                    <div className="input-file w-[150px] h-[30px] relative cursor-pointer">
                                      <input type="file"
                                        ref={fileInputRef}
                                        disabled={!formikWorkspace?.title}
                                        accept="image/png,image/jpeg,image/svg+xml"
                                        className="bg-transparent absolute inset-0 opacity-0"
                                        placeholder="Subir imagen" title="Subir imagen"
                                        onChange={handleImageChange}>
                                      </input>
                                    </div>
                                  </div>
                                  <button
                                    type="button"
                                    onClick={handleButtonClick}
                                    className="relative bg-[#073147] w-[100px] h-[32px] grid place-content-center ff-cp--bold text-[12px] text-[#07DACB] text-center border-[1.5px] border-[#07DACB] rounded-[10px] cursor-pointer"
                                  >
                                    <span>Subir imagen</span>
                                  </button>
                                </div>
                                <div className="w-full grid justify-end">
                                  <div className="grid items-center min-w-[250px] max-w-[310px] h-[32px] bg-[#070625] px-[16px] text-[#ffffff] ff-cp--regular text-[12px] border-[1.5px] border-[#FFFFFF] rounded-[10px]">
                                    <span className="text-[11px] leading-[11px] ff-cp--light text-white/70">{imageName ?? 'Ningún archivo seleccionado'}</span>
                                  </div>
                                </div>
                                <p className="text-white text-right text-[10px]">Limite de subida 100KB (PNG/SVG/JPG)</p>

                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-end">
                          <button
                            type="submit"
                            className="ff-cp--bold text-[14px] text-[#ffffff] w-[167px] h-[36px] bg-cover bg-no-repeat bg-center opacity-80 hover:opacity-100 hover:scale-110 disabled:opacity-50"
                            disabled={!formikWorkspace?.title || !groupForm.values.name}
                            style={{ backgroundImage: `url(${btnExport})` }}>
                            Crear nuevo grupo
                          </button>
                        </div>
                      </form>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div className={`col-span-1 ${showAccessSection ? 'row-span-4' : 'row-span-1 my-4'} min-w-full`}>
              <div className={`${showUserSection ? 'h-full border-2' : 'h-0 border-[1px]'} relative border-[#60DFFF] rounded-[20px]`}>
                <span className="absolute top-[-16px] left-[25px] h-[32px] bg-[#07DBCB] text-[14px] text-[#000000] rounded-[20px] p-[6px] pl-[14px] pr-[14px] leading-normal ff-cp--bold z-20">
                  Creación de NUEVOS USUARIOS Admin
                </span>
                <div onClick={() => setShowUserSection(!showUserSection)} className="w-[32px] h-[32px] cursor-pointer absolute -top-[16px] left-[325px] bg-cover bg-center bg-no-repeat hover:scale-110" style={{ backgroundImage: `url(${showUserSection ? btnArrowUpSection : btnArrowDownSection})` }}></div>
                {
                  showUserSection &&
                  <div className="overflow-hidden h-full pt-[43px] px-[30px] pb-[24px] rounded-[20px] bg-gradient-to-b from-[#051d3e]">
                    <p className="text-[13px] text-white leading-none ff-cp--regular mb-[40px]">
                      En esta sección se puede crear <span className="ff-cp--bold">un nuevo perfil de usuario tipo admin</span> que podrá acceder a la data que brindan los participantes que utilizaron M.O.V.E Match.
                    </p>

                    <form onSubmit={adminFormik.handleSubmit}>
                      <div className="grid grid-cols-2">
                        <div>
                          <div className="grid grid-cols-[.35fr_.65fr] gap-3 items-center mb-[14px]">
                            <p className="text-white text-[16px] min-w-[115px] ff-cp--bold">
                              Nombre de usuario
                            </p>
                            <input
                              type="text"
                              name="name"
                              value={adminFormik.values.name}
                              onChange={adminFormik.handleChange}
                              className="min-w-[210px] max-w-[310px] h-[32px] bg-[#070625] pl-[16px] text-[#ffffff] ff-cp--regular text-[12px] border-[1.5px] border-[#FFFFFF] rounded-[10px]"
                              placeholder="Escribe el nombre aquí"
                            />
                          </div>
                          <div className="grid grid-cols-[.35fr_.65fr] gap-3 items-center mb-[14px]">
                            <p className="text-white text-[16px] min-w-[115px] ff-cp--bold">
                              Contraseña
                            </p>
                            <input
                              type="password"
                              name="password"
                              value={adminFormik.values.password}
                              onChange={adminFormik.handleChange}
                              className="min-w-[210px] max-w-[310px] h-[32px] bg-[#070625] pl-[16px] text-[#ffffff] ff-cp--regular text-[12px] border-[1.5px] border-[#FFFFFF] rounded-[10px]"
                              placeholder="Escribe la contraseña"
                            />
                          </div>
                          <div className="grid grid-cols-[.35fr_.65fr] gap-3 items-center mb-[14px]">
                            <p className="text-white text-[16px] min-w-[115px] ff-cp--bold">
                              Nombre de empresa
                            </p>
                            <div className="relative bg-[#073147] min-w-[210px] max-w-[310px] h-[32px] flex items-center ff-cp--bold text-[12px] text-[#07DACB] border-[1.5px] border-[#07DACB] rounded-[10px]">
                              <img
                                src={iconSearch}
                                alt=""
                                className="w-[19px] h-[19px] absolute top-0 bottom-0 right-[7px] my-auto"
                              />

                              <Dropdown
                                items={workspaces}
                                attribute="title"
                                callback={setFormikCompany}
                                placeholder="Escribe nombre de empresa"
                                name="workspace_id"
                                clear={clearFormikCompany}
                                position='TOP'
                              ></Dropdown>
                            </div>
                          </div>
                        </div>
                        <div className="w-full grid justify-items-end">
                          <div className="w-full max-w-[500px]">
                            <div className="w-full grid grid-cols-[.35fr_.65fr] gap-3 items-center mb-[14px]">
                              <p className="text-white text-[16px] min-w-[115px] ff-cp--bold">
                                Correo
                              </p>
                              <input
                                type="email"
                                name="email"
                                value={adminFormik.values.email}
                                onChange={adminFormik.handleChange}
                                className="min-w-[210px] max-w-[310px] h-[32px] bg-[#070625] pl-[16px] text-[#ffffff] ff-cp--regular text-[12px] border-[1.5px] border-[#FFFFFF] rounded-[10px]"
                                placeholder="Escribe el correo"
                              />
                            </div>
                            <div className="grid grid-cols-[.35fr_.65fr] gap-3 items-center mb-[14px]">
                              <p className="text-white text-[16px] min-w-[115px] ff-cp--bold">
                                Repetir contraseña
                              </p>
                              <input
                                type="password"
                                name="repeat_password"
                                value={adminFormik.values.repeat_password}
                                onChange={adminFormik.handleChange}
                                className="min-w-[210px] max-w-[310px] h-[32px] bg-[#070625] pl-[16px] text-[#ffffff] ff-cp--regular text-[12px] border-[1.5px] border-[#FFFFFF] rounded-[10px]"
                                placeholder="Escribe la contraseña"
                              />
                            </div>
                            <div className="grid grid-cols-[.35fr_.65fr] gap-3 items-center mb-[14px]">
                              <p className="text-white text-[16px] min-w-[115px] ff-cp--bold">
                                Grupo de acceso
                              </p>
                              <div className="relative bg-[#073147] min-w-[210px] max-w-[310px] h-[32px] flex items-center ff-cp--bold text-[12px] text-[#07DACB] border-[1.5px] border-[#07DACB] rounded-[10px]">
                                <img
                                  src={iconSearch}
                                  alt=""
                                  className="w-[19px] h-[19px] absolute top-0 bottom-0 right-[7px] my-auto"
                                />
                                <Dropdown
                                  mode="MULTI"
                                  items={groupsByName}
                                  attribute="name"
                                  callback={setFormikAdminGroupAccess}
                                  placeholder="Escribe el grupo de acceso"
                                  name="groups"
                                  identifier="groups_multi"
                                  allowAdd={true}
                                  clear={clearFormikGroupAccess}
                                  position="TOP"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-end">
                        <button
                          type="submit"
                          className="ff-cp--bold text-[14px] text-[#ffffff] w-[167px] h-[36px] cursor-pointer bg-cover bg-no-repeat bg-center opacity-80 hover:opacity-100 hover:scale-110 disabled:opacity-50"
                          disabled={!adminFormik.values.email || !adminFormik.values.name || !adminFormik.values.password || !adminFormik.values.repeat_password || !formikCompany?.id || formikAdminGroupAccess.length === 0}
                          style={{ backgroundImage: `url(${btnExport})` }}
                        >
                          Crear Admin
                        </button>
                      </div>
                    </form>
                  </div>
                }
              </div>
            </div>
            <div className={`col-span-1 ${showAccessSection ? 'row-span-2' : 'row-span-1 my-4'} min-w-full`}>
              <div className={`${showPermisionSection ? 'h-full border-2' : 'h-0 border-[1px]'} relative border-[#60DFFF] rounded-[20px]`}>
                <div className="relative h-full rounded-[20px]">
                  <span className="absolute top-[-16px] left-[25px] h-[32px] bg-[#07DBCB] text-[14px] text-[#000000] rounded-[20px] p-[6px] pl-[14px] pr-[14px] leading-normal ff-cp--bold z-20">
                    Administración de PERMISOS USUARIOS Admin
                  </span>
                  <div onClick={() => setShowPermisionSection(!showPermisionSection)} className="w-[32px] h-[32px] cursor-pointer absolute -top-[16px] left-[385px] bg-cover bg-center bg-no-repeat hover:scale-110" style={{ backgroundImage: `url(${showPermisionSection ? btnArrowUpSection : btnArrowDownSection})` }}></div>
                  {
                    showPermisionSection &&
                    <div className="overflow-hidden h-full pt-[43px] px-[30px] pb-[24px] rounded-[20px] bg-gradient-to-b from-[#051d3e]">
                      <p className="text-[13px] text-white leading-none ff-cp--regular mb-[40px]">
                        En esta sección se puede <span className="ff-cp--bold">administrar los permisos de acceso de un perfil de usuario tipo admin</span> que podrá acceder en distintos niveles a la data que brindan los participantes que utilizaron M.O.V.E Match.
                      </p>
                      <form onSubmit={adminAccessFormik.handleSubmit}>
                        <div className="grid grid-cols-2">
                          <div>
                            <div className="grid grid-cols-[.35fr_.65fr] gap-3 items-center mb-[14px]">
                              <p className="text-white text-[16px] min-w-[115px] ff-cp--bold">
                                Buscar usuario Admin
                              </p>
                              <div className="relative bg-[#073147] min-w-[210px] max-w-[310px] h-[32px] flex items-center ff-cp--bold text-[12px] text-[#07DACB] border-[1.5px] border-[#07DACB] rounded-[10px]">
                                <Dropdown
                                  items={users}
                                  attribute="name"
                                  callback={setFormikAdminUser}
                                  placeholder="Escribe el nombre del admin"
                                  name="user_id"
                                  clear={clearFormikAdminUser}
                                  allowAdd={false}
                                ></Dropdown>
                                <img
                                  src={iconSearch}
                                  alt=""
                                  className="w-[19px] h-[19px] absolute top-0 bottom-0 right-[7px] my-auto"
                                />
                              </div>
                            </div>
                            <div className="grid grid-cols-[.35fr_.65fr] gap-3 items-center mb-[14px]">
                              <p className="text-white text-[16px] min-w-[115px] ff-cp--bold">
                                Permisos generales
                              </p>
                              <div className="relative bg-[#073147] min-w-[210px] max-w-[310px] h-[32px] flex items-center ff-cp--bold text-[12px] text-[#07DACB] border-[1.5px] border-[#07DACB] rounded-[10px]">
                                <Dropdown
                                  mode="MULTI"
                                  items={roles}
                                  attribute="title"
                                  callback={setFormikAdminGroupAccess}
                                  placeholder="Selecciona los permisos de administrador"
                                  name="access"
                                  identifier="access_multi"
                                  clear={clearFormikGroupAccess}
                                  position="TOP"
                                />
                                <img
                                  src={iconSelector}
                                  alt=""
                                  className="w-[12px] h-[16px] absolute top-0 bottom-0 right-[12px] my-auto"
                                />
                              </div>
                            </div>
                          </div>
                          <div className="w-full grid justify-items-end">
                            <div className="w-full max-w-[500px]">
                              <div className="grid grid-cols-[.35fr_.65fr] gap-3 items-center mb-[14px]">
                                <p className="text-white text-[16px] min-w-[115px] ff-cp--bold">
                                  Nombre de empresa
                                </p>
                                <div className="relative bg-[#073147] min-w-[210px] max-w-[310px] h-[32px] flex items-center ff-cp--bold text-[12px] text-[#07DACB] border-[1.5px] border-[#07DACB] rounded-[10px]">
                                  <img
                                    src={iconSearch}
                                    alt=""
                                    className="w-[19px] h-[19px] absolute top-0 bottom-0 right-[7px] my-auto"
                                  />
                                  <Dropdown
                                    items={workspaces}
                                    attribute="title"
                                    callback={setFormikWorkspaceAccess}
                                    placeholder="Escribe nombre de empresa"
                                    name="workspace_id"
                                    clear={clearFormikWorkspace}
                                    position="BOTTOM"
                                  ></Dropdown>
                                </div>
                              </div>
                              <div className="grid grid-cols-[.35fr_.65fr] gap-3 items-center mb-[14px]">
                                <p className="text-white text-[16px] min-w-[115px] ff-cp--bold">
                                  Grupos de acceso
                                </p>
                                <div className="relative bg-[#073147] min-w-[210px] max-w-[310px] h-[32px] flex items-center ff-cp--bold text-[12px] text-[#07DACB] border-[1.5px] border-[#07DACB] rounded-[10px]">
                                  <Dropdown
                                    mode="MULTI"
                                    items={groupsByWorkspace}
                                    attribute="name"
                                    callback={setFormikGroupAccessPermission}
                                    placeholder="Escribe el grupo de acceso"
                                    name="access_pass_id"
                                    identifier="groups_access_multi"
                                    allowAdd={false}
                                    clear={clearFormikGroupAccessPermission}
                                    position="TOP"
                                  />
                                  <img
                                    src={iconSearch}
                                    alt=""
                                    className="w-[19px] h-[19px] absolute top-0 bottom-0 right-[7px] my-auto"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex justify-end">
                          <button type="submit"
                            className="ff-cp--bold text-[14px] text-[#ffffff] w-[167px] h-[36px] bg-cover bg-no-repeat bg-center opacity-80 hover:opacity-100 hover:scale-110 disabled:opacity-50"
                            disabled={!formikAdminUser?.id || formikAdminGroupAccess.length === 0 || validateAdminAccess()}
                            style={{ backgroundImage: `url(${btnExport})` }}>
                            Guardar cambios
                          </button>
                        </div>
                      </form>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AccessSuperadmin;
