import React, { useState, useRef, useEffect } from "react";

type DropdownProps = {
  items: any[];
  placeholder: string;
  attribute: string;
  name: string;
  mode?: string;
  identifier?: string;
  allowAdd?: boolean;
  position?: string,
  callback: (value: any) => void;
  clear?: boolean;
};

const Dropdown = ({
  items = [],
  mode = "SIMPLE",
  attribute,
  placeholder,
  name,
  identifier,
  allowAdd = false,
  clear = false,
  position = 'BOTTOM',
  callback,
}: DropdownProps) => {
  const [options, setOptions] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    undefined
  );

  const [selectOptionList, setSelectOptionList] = useState<any[]>([]);
  const [searchOption, setSearchOption] = useState<any>(undefined);

  const [optionValue, setOptionValue] = useState<string>("");
  const dropdownRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if(clear) {
      setOptionValue('');
      setSelectedOption(undefined)
      setSelectOptionList([])
    }
  }, [clear])

  const handleOptionClick = (
    option: any,
    isnew: boolean = false
  ) => {
    if (mode === "SIMPLE") {
      if(allowAdd) setOptionValue(option[attribute] ?? "")
      else setOptionValue(option?.id ? option[attribute] : "");
      setSelectedOption(option.id);
      setIsOpen(false);
      setOptions(items);
      callback(option);
    }

    if (mode === "MULTI") {
      let options_list = selectOptionList;

      const index = options_list.findIndex((opt) => opt.id === option?.id);
      if (index === -1) {
        if (isnew && allowAdd) {
          setOptions([...options, option]);
          option.id = options_list.length;
          setSearchOption(undefined);
          setOptionValue("");
        }
        options_list.push(option);
      } else {
        options_list.splice(index, 1);
      }

      setSelectOptionList([...options_list]);
      callback(options_list);
    }
  };

  const isInclude = (option: any) => {
    return selectOptionList.findIndex((op) => op.id === option.id) !== -1;
  };

  const searchOptions = (event: any) => {
    const option = event.target.value;
    setOptionValue(option);
    if (option !== "") {
      let list_items = items;
      list_items = list_items.filter((item_list: any) =>
        (item_list[attribute] as string)
          .toLowerCase()
          .includes(option.toLowerCase(identifier))
      );
      setOptions(list_items);
      if (
        !list_items.filter(
          (item_list: any) =>
            (item_list[attribute] as string).toLowerCase() ===
            option.toLowerCase()
        )?.length && allowAdd
      ) {
        setSearchOption({ name: option });
      } else setSearchOption(undefined);
    } else {
      setSearchOption(undefined);
      setOptions(items);
    }
    
    callback({ [attribute]: option })
  };

  useEffect(() => {
    setOptions(items);
  }, [items]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        (dropdownRef.current && dropdownRef.current.contains(event.target)) ||
        (mode === "MULTI" && event.target.classList?.contains(identifier))
      )
        setIsOpen(true);
      else setIsOpen(false);
    };

    window.addEventListener("click", handleClickOutside);

    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [dropdownRef]);

  return (
    <div className="relative w-full" ref={dropdownRef}>
      {mode === "MULTI" && !isOpen && selectOptionList.length > 0 && (
        <div
          className="w-full h-full flex gap-2 px-2 cursor-pointer"
          onClick={() => setIsOpen(true)}
        >
          {selectOptionList.slice(0, 1).map((select) => {
            return (
              <div
                key={select.id}
                className={`bg-[#07DACB] text-[#070625] rounded-md px-2 py-1 text-[10px] ${identifier}`}
              >
                {select[attribute]}
              </div>
            );
          })}
          {selectOptionList.length > 1 && (
            <div
              className={`bg-[#07DACB] text-[#070625] rounded-md px-2 py-1 text-[10px] ${identifier}`}
            >
              +{selectOptionList.length - 1}
            </div>
          )}
        </div>
      )}
      {(mode === "SIMPLE" ||
        (mode === "MULTI" && isOpen) ||
        selectOptionList.length === 0) && (
        <input
          name={name}
          value={optionValue}
          onChange={(e) => searchOptions(e)}
          placeholder={placeholder}
          className="bg-transparent text-[#07DACB] font-semibold py-2 rounded-[10px] w-full text-left pl-4 pr-10 overflow-visible text-ellipsis placeholder-[#07DACB] placeholder:font-medium"
          onClick={() => (mode === "MULTI" ? null : setIsOpen(!isOpen))}
          onFocus={() => (mode === "SIMPLE" ? null : setIsOpen(true))}
        ></input>
      )}
      {isOpen && (
        <div className={`absolute mt-1 z-10 w-full bg-[#073147] px-2 py-2 shadow-lg rounded-[10px] border border-[#07DACB] border-solid ${position === 'BOTTOM' ? ' max-h-[105px]' : 'bottom-10 max-h-[110px]'}`}>
          <ul className={`dropdown-scroll w-full pr-1 overflow-auto ${position === 'BOTTOM' ? 'max-h-[90px]' : 'bottom-10 max-h-[95px]'}`}>
            {mode === "SIMPLE" && (
              <li
                onClick={() => handleOptionClick(!optionValue ? '' : { [attribute]: optionValue })}
                className={`min-h-[32px] px-2 py-2 mb-1 font-semibold cursor-pointer rounded-[10px] ${
                  !selectedOption ? "bg-[#07dacc30]" : "bg-transparent"
                } hover:bg-[#07dacc30]`}
              >
                { !optionValue || !allowAdd ? 'Ninguno' : optionValue}
              </li>
            )}

            {mode === "MULTI" && searchOption !== undefined && (
              <li
                onClick={() => handleOptionClick(searchOption, true)}
                className={`min-h-[32px] px-2 py-2 my-1 font-semibold cursor-pointer rounded-[10px] ${
                  selectedOption === searchOption.id
                    ? "bg-[#07dacc30]"
                    : "bg-transparent"
                } hover:bg-[#07dacc30] ${mode === "MULTI" ? "flex" : ""}`}
              >
                <input
                  type="checkbox"
                  checked={isInclude(searchOption)}
                  onClick={() => handleOptionClick(searchOption, true)}
                  onChange={() => handleOptionClick(searchOption, true)}
                  className={`mr-[6px] cursor-pointer w-[16px] h-[16px] appearance-none rounded-[4px] ${
                    isInclude(searchOption)
                      ? "bg-[#07DACB] border-none"
                      : "bg-transparent border-[#07DACB] border-[2px] border-solid"
                  }`}
                  style={{
                    backgroundImage: isInclude(searchOption)
                      ? `url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e")`
                      : undefined,
                  }}
                />
                {searchOption[attribute]}
              </li>
            )}
            {options.map((item: any) => {
              return (
                <li
                  key={item.id}
                  onClick={() => handleOptionClick(item)}
                  className={`min-h-[32px] px-2 py-2 my-1 font-semibold cursor-pointer rounded-[10px] ${
                    selectedOption === item.id
                      ? "bg-[#07dacc30]"
                      : "bg-transparent"
                  } hover:bg-[#07dacc30] ${mode === "MULTI" ? "flex" : ""}`}
                >
                  {mode === "MULTI" && (
                    <input
                      type="checkbox"
                      checked={isInclude(item)}
                      onClick={() => handleOptionClick(item)}
                      onChange={() => handleOptionClick(item)}
                      className={`mr-[6px] cursor-pointer w-[16px] h-[16px] appearance-none rounded-[4px] ${
                        isInclude(item)
                          ? "bg-[#07DACB] border-none"
                          : "bg-transparent border-[#07DACB] border-[2px] border-solid"
                      }`}
                      style={{
                        backgroundImage: isInclude(item)
                          ? `url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e")`
                          : undefined,
                      }}
                    />
                  )}
                  {item[attribute]}
                </li>
              );
            })}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
