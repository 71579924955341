
import { API_ROUTES } from './../../constants/api_routes';
import { HEADERS } from "../../constants/connection";
import { sendMail } from './mail';


export const authLogin = async (user: any) => {

  return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.AUTH.LOGIN}`, {
    method: 'POST',
    headers: {
      ...HEADERS
    },
    body: JSON.stringify(user),
  }).then((response) => response.json())
    .then(async (data_auth) => {
      let result: any = {};
      if(data_auth.status) {
        result = data_auth;
        localStorage.setItem("access_token", data_auth.access_token)
        localStorage.setItem("user", JSON.stringify(data_auth.user))
      }
      else{
        result = {...data_auth};
      }
      return result;
    })
    .catch(error => {
      return false
    });
}

export const forgotPassword = async (user: any) => {

  return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.AUTH.FORGOT.PASSWORD}`, {
    method: 'POST',
    headers: {
      ...HEADERS
    },
    body: JSON.stringify(user),
  }).then((response) => response.json())
    .then(async(data) => {
      if(data.status) {
        await sendMail(user['email'], 'recovery', data.recovery_token)
      }
      return data;
    })
    .catch(error => {
      return false
    });
}

export const resetPassword = async (user: any) => {
  const body = {
    password: user['password']
  }
  return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.AUTH.FORGOT.RESET}`, {
    method: 'POST',
    headers: {
      ...HEADERS,
      Authorization: `Bearer ${user.token}`
    },
    body: JSON.stringify(body),
  }).then((response) => response.json())
    .then(data => {
      return data;
    })
    .catch(error => {
      return false
    });
}

export const logoutAdmin = async () => {
  return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.AUTH.LOGOUT}`, {
    method: 'POST',
    headers: {
      ...HEADERS,
      Authorization: `Bearer ${localStorage.getItem("access_token")}`
    },
  }).then((response) => response.json())
    .then(data => {
      return data;
    })
    .catch(error => {
      return false
    });
}