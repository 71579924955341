
import { API_ROUTES } from '../../constants/api_routes';
import { HEADERS } from "../../constants/connection";

export const sendMail = async (emails: string, reason: string, token: string) => {
    const body = {
        emails: emails,
        reason: reason,
        token: token,
        role: 'ADMIN'
    }
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.MAIL}`, {
        method: 'POST',
        headers: {
            ...HEADERS
        },
        body: JSON.stringify(body)
    })
    .then((response) => response.json())
        .then(data => {
            return data;
        })
    .catch(error => {
        return false
    });
}