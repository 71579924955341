
import { API_ROUTES } from './../../constants/api_routes';
import { HEADERS, HEADER_FILE } from "../../constants/connection";

export const getAllWorkspaces = async () => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.WORKSPACE.GET.ALL}`, {
        method: 'GET',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
    })
    .then((response) => response.json())
        .then(data => {
            return data;
        })
    .catch(error => {
        return false
    });
}

export const createWorkspace = async (title: string, logo: any, institution_id?: string) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.WORKSPACE.CREATE}`, {
        method: 'POST',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        body: JSON.stringify({title, logo, institution_id})
    })
    .then((response) => response.json())
        .then(data => {
            return data;
        })
    .catch(error => {
        return false
    });
}

export const updateWorkspace = async (workspace_id: string, title: string, logo: any, institution_id?: string) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.WORKSPACE.UPDATE}${workspace_id}`, {
        method: 'PATCH',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        body: JSON.stringify({title, logo, institution_id})
    })
    .then((response) => response.json())
        .then(data => {
            return data;
        })
    .catch(error => {
        return false
    });
}