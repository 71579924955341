
import { createStore } from "redux";
import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT } from "../actions/type";

const user =  localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user') || '{}') : ''

const intitialState = user 
  ? { authenticated: true }
  : { authenticated: false };

const reducer = (state = intitialState, action: { type: any; }) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
        return { ...state, authenticated: true };
    case LOGIN_FAIL:
        return { ...state, authenticated: false };
    case LOGOUT:
        return { ...state, authenticated: false };    
    default:
      return state;
  }
};

const store = createStore(reducer);

export default store;