import { useState, useEffect } from 'react'
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';

// Services
import { groupTriedReport, groupUserReport } from "../../services/apis/admin";
import { getAllAccessPassByUserId } from '../../services/apis/access_pass'
import { getAllUsersByGroup } from '../../services/apis/user'

// Components
import Dropdown from '../../layout/shared/Dropdown'
import DashboardSidebar from '../../components/dashboard/DashboardSidebar'

// Methods
import { getRole, getUser } from '../../common/methods/storage';

// Images
import iconLogo from '../../assets/images/icons/icon-logo.svg'
import iconSelector from '../../assets/images/icons/icon-selector.svg'
import iconReport from '../../assets/images/icons/icon-report-white.svg'
import btnExport from '../../assets/images/buttons/btn-export.svg'
import iconLoading from '../../assets/images/icons/icon-spinner.svg'


const DashboardSuperadmin = () => {

    const user = getUser();
    const role = getRole();

    const [value, setValue] = useState<any>(undefined);
    const [value2, setValue2] = useState<any>(undefined);

    const [groups, setGroups] = useState<any[]>([])
    const [userByGroup, setUsersByGroup] = useState<any[]>([])

    const [formikTriesGroups, setFormikTriesGroups] = useState<any[]>([]);
    const [clearFormikTriesGroups, setClearFormikTriesGroups] = useState<boolean>(false);
    const [formikTries, setFormikTries] = useState<any[]>([]);
    const [clearFormikTries, setClearFormikTries] = useState<boolean>(false);

    const [formikUserGroup, setFormikUserGroup] = useState<any[]>([]);
    const [clearFormikUserGroup, setClearFormikUserGroup] = useState<boolean>(false);
    const [formikUser, setFormikUser] = useState<any>(null);
    const [clearFormikUser, setClearFormikUser] = useState<boolean>(false);

    const [loading, setLoading] = useState<boolean>(false)
    const [loading2, setLoading2] = useState<boolean>(false)

    const [loadingGeneral, setLoadingGeneral] = useState<boolean>(false)

    const TRIES_LIST = [
        { id: 'FIRST', name: 'Primer intento'},
        { id: 'LAST', name: 'Último intento'},
        { id: 'ALL', name: 'Todos los intentos'}
    ]

    useEffect(() => { getGroups() }, [])

    useEffect(() => {
        if(formikUserGroup.length > 0) getUsersByGroup()
        else setUsersByGroup([])
    },[formikUserGroup])
    
    const exportGroupTriedReport = async () => {
        setLoadingGeneral(true)
        const [first_date, end_date] = value
        const initial_date = new Date(first_date)
        initial_date.setUTCHours(0, 0, 0, 0);

        const finish_date = new Date(end_date)
        finish_date.setUTCHours(23, 59, 59, 999);
       await groupTriedReport({ tried: formikTries[0]?.id, group_id: formikTriesGroups[0]?.id, first_date: initial_date.toISOString(), end_date: finish_date.toISOString() }).then((blob: any) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Reporte_por_intento_${formikTriesGroups[0]?.name}.xlsx`);
            document.body.appendChild(link);
            link.click();
            setClearFormikTries(true)
            setClearFormikTriesGroups(true)
            setLoading(true)
            setTimeout(() => {
                setFormikTries([])
                setFormikTriesGroups([])
                setClearFormikTries(false)
                setClearFormikTriesGroups(false)
                setValue(undefined)
                setLoading(false)
                setLoadingGeneral(false)
            }, 400);
        });
    };

    const exportGroupUserReport = async () => {
        setLoadingGeneral(true)
        const [first_date, end_date] = value2
        const initial_date = new Date(first_date)
        initial_date.setUTCHours(0, 0, 0, 0);

        const finish_date = new Date(end_date)
        finish_date.setUTCHours(23, 59, 59, 999);

        await groupUserReport({ user_id: formikUser?.id, group_id: formikUserGroup[0]?.id, first_date: initial_date.toISOString(), end_date: finish_date.toISOString() }).then((blob: any) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Reporte_por_usuario${formikUser?.id ? ('_' + formikUser?.name + '_' + formikUser?.last_name) : 's'}.xlsx`);
            document.body.appendChild(link);
            link.click();
            setClearFormikUser(true)
            setClearFormikUserGroup(true)
            setLoading2(true)
            setTimeout(() => {
                setFormikUser(null)
                setFormikUserGroup([])
                setClearFormikUser(false)
                setClearFormikUserGroup(false)
                setValue2(undefined)
                setLoading2(false)
                setLoadingGeneral(false)
            }, 400);
        });
    };

    const getGroups = async () => {
        if(user) {
            await getAllAccessPassByUserId(user.id)
            .then( data => { setGroups(data) })
        }
    }

    const getUsersByGroup = async () => {
        const group_id = formikUserGroup[0].id
        await getAllUsersByGroup(group_id)
         .then( users => { setUsersByGroup(users.map( (dt: any) => ({...dt, fullname: dt.name  + ' ' + dt.last_name}))) })
    }
    

    return (
        <section id="dashboard">
            {
                loadingGeneral &&
                <div className="fixed bg-black/60 inset-0 grid place-content-center z-[100]">
                    <img src={iconLoading} width={80} height={80}></img>
                </div>
            }
            <div className="h-screen w-screen overflow-hidden">
                <div className="h-full w-full p-[24px] grid grid-cols-[44px_auto] grid-rows-1 gap-[24px]">
                    <div className="col-[1_/_2] row-1">
                        <DashboardSidebar current={0} role={role} />
                    </div>
                    <div className="col-[2_/_2] min-h-full min-w-full row-1 gap-6 h-full grid grid-rows-[71px_auto] grid-cols-2">
                        <div className="col-span-1 row-[1_/_2] min-w-full">
                            <div className="relative h-full border-[#60DFFF] bg-gradient-to-b from-[#051d3e] border-2 rounded-[20px]">
                                <span className="absolute top-[-16px] left-[24px] w-[160px] h-[32px] bg-[#07DBCB] text-[14px] text-[#000000] rounded-[20px] p-[6px] pl-[14px] leading-normal ff-cp--bold">Administrador</span>
                                <div className="h-[64px] pl-[38px] pr-[24px] pt-[16px] pb-[9px] flex justify-between items-center rounded-t-[20px] bg-gradient-to-b from-[#043658] to-transparent">
                                    <h2 className="ff-cp--bold text-[#ffffff] text-[24px]">M.O.V.E Match</h2>
                                    <div className="flex items-center">
                                        <h3 className="ff-cp--bold text-[16px] text-[#07DACB] leading-none mr-[32px]">{user?.nickname}</h3>
                                        <img src={iconLogo} alt="" className="w-[34px] h-[34px]" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid grid-cols-2 grid-rows-1 gap-6 col-span-1 row-[1_/_2] min-w-full"></div>
                        <div className="col-span-1 row-span-3 min-w-full">
                            <div className="border-[#60DFFF] relative h-full border-2 rounded-[20px]">
                                <span className="absolute top-[-16px] left-[25px] h-[32px] bg-[#07DBCB] text-[14px] text-[#000000] rounded-[20px] p-[6px] pl-[14px] pr-[14px] leading-normal ff-cp--bold z-20">Descargar DATA DE USUARIOS por grupo</span>
                                <div className="overflow-hidden rounded-[20px] h-full bg-gradient-to-b from-[#051d3e]">

                                    <div className="bg-gradient-to-t from-[#043658] px-[30px] py-[40px]">
                                        <p className="text-[12px] font-extralight text-white leading-none">En esta sección se puede generar y <span className="font-bold text-[13px]">descargar un archivo tipo tabla de cálculo</span> para visualizar la <span className="font-bold text-[13px]">información de todos los participantes</span> que utilizaron M.O.V.E Match.</p>
                                    </div>

                                    <form className="px-[30px] py-[40px]" action="">
                                        <div className="flex justify-between items-center mb-[14px]">
                                            <p className="text-white text-[18px] ff-cp--bold">Fecha de inicio - fin</p>
                                            <div className="flex justify-between w-[310px]">
                                                {
                                                    !loading && 
                                                    <LocalizationProvider  dateAdapter={AdapterDateFns}>
                                                        <DateRangePicker
                                                            localeText={{ start: '', end: '' }}
                                                            value={value} onChange={(newValue) => setValue(newValue)} />
                                                    </LocalizationProvider>
                                                }
                                            </div>
                                        </div>
                                        <div className="flex justify-between gap-3 items-center mb-[14px]">
                                            <p className="text-white text-[16px] min-w-[115px] ff-cp--bold">
                                                Grupo de acceso
                                            </p>
                                            <div className="relative bg-[#073147] min-w-[310px] max-w-[310px] h-[32px] flex items-center ff-cp--bold text-[12px] text-[#07DACB] border-[1.5px] border-[#07DACB] rounded-[10px]">
                                                <img
                                                    src={iconSelector}
                                                    alt=""
                                                    className="w-[19px] h-[19px] absolute top-0 bottom-0 right-[7px] my-auto"
                                                />
                                                <Dropdown
                                                    mode="MULTI"
                                                    items={groups}
                                                    attribute="name"
                                                    callback={setFormikTriesGroups}
                                                    placeholder="Escribe el grupo de acceso"
                                                    name="groups"
                                                    identifier="groups_multi"
                                                    allowAdd={true}
                                                    clear={clearFormikTriesGroups}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex justify-between gap-3 items-center mb-[14px]">
                                                <p className="text-white text-[16px] min-w-[115px] ff-cp--bold">
                                                    # de Intento
                                                </p>
                                                <div className="relative bg-[#073147] min-w-[310px] max-w-[310px] h-[32px] flex items-center ff-cp--bold text-[12px] text-[#07DACB] border-[1.5px] border-[#07DACB] rounded-[10px]">
                                                    <img
                                                    src={iconSelector}
                                                    alt=""
                                                    className="w-[19px] h-[19px] absolute top-0 bottom-0 right-[7px] my-auto"
                                                    />
                                                    <Dropdown
                                                    mode="MULTI"
                                                    items={TRIES_LIST}
                                                    attribute="name"
                                                    callback={setFormikTries}
                                                    placeholder="Selecciona el número de intento"
                                                    name="groups"
                                                    identifier="groups_multi"
                                                    allowAdd={true}
                                                    clear={clearFormikTries}
                                                    />
                                                </div>
                                            </div>
                                        <div className="flex justify-end">
                                            <button type="button" disabled={formikTriesGroups.length == 0 || value === undefined || formikTries.length === 0 } onClick={() => exportGroupTriedReport() } className="ff-cp--bold text-[14px] text-[#ffffff] w-[167px] h-[36px] bg-cover bg-center bg-no-repeat opacity-80 hover:opacity-100 hover:scale-110 flex items-center justify-center gap-[7px] disabled:opacity-40" style={{ backgroundImage: `url(${btnExport})` }}>Generar Reporte <div className="h-[17px] w-[12px] bg-cover bg-no-repeat bg-center" style={{backgroundImage: `url(${iconReport})`}}></div></button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-1 row-span-3 min-w-full">
                            <div className="h-full relative border-[#60DFFF] border-2 rounded-[20px]">
                                <div className="relative h-full rounded-[20px]">
                                    <span className="absolute top-[-16px] left-[25px] h-[32px] bg-[#07DBCB] text-[14px] text-[#000000] rounded-[20px] p-[6px] pl-[14px] pr-[14px] leading-normal ff-cp--bold z-20">Descargar DATA DE USUARIO específico</span>
                                    <div className="overflow-hidden h-full rounded-[20px] bg-gradient-to-b from-[#051d3e]">

                                        <div className="bg-gradient-to-t from-[#043658] px-[30px] py-[40px]">
                                            <p className="text-[12px] font-extralight text-white leading-none">En esta sección se puede generar y <span className="font-bold text-[13px]">descargar un archivo tipo tabla de cálculo</span> para visualizar la <span className="font-bold text-[13px]">información en detalle de cada participante</span> que utilizó M.O.V.E Match.</p>
                                        </div>

                                        <form className="px-[30px] py-[40px]" action="">
                                            <div className="flex justify-between items-center mb-[14px]">
                                                <p className="text-white text-[18px] ff-cp--bold">Fecha de inicio - fin</p>
                                                <div className="flex justify-between w-[310px]">
                                                    {
                                                        !loading2 &&
                                                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                            <DateRangePicker
                                                                localeText={{ start: '', end: '' }}
                                                                value={value2} onChange={(newValue) => setValue2(newValue)} />
                                                        </LocalizationProvider>
                                                    }
                                                </div>
                                            </div>
                                            <div className="flex justify-between gap-3 items-center mb-[14px]">
                                                <p className="text-white text-[16px] min-w-[115px] ff-cp--bold">
                                                    Grupo de acceso
                                                </p>
                                                <div className="relative bg-[#073147] min-w-[310px] max-w-[310px] h-[32px] flex items-center ff-cp--bold text-[12px] text-[#07DACB] border-[1.5px] border-[#07DACB] rounded-[10px]">
                                                    <img
                                                    src={iconSelector}
                                                    alt=""
                                                    className="w-[19px] h-[19px] absolute top-0 bottom-0 right-[7px] my-auto"
                                                    />
                                                    <Dropdown
                                                    mode="MULTI"
                                                    items={groups}
                                                    attribute="name"
                                                    callback={setFormikUserGroup}
                                                    placeholder="Escribe el grupo de acceso"
                                                    name="groups"
                                                    identifier="groups_user_multi"
                                                    allowAdd={true}
                                                    clear={clearFormikUserGroup}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex justify-between gap-3 items-center mb-[14px]">
                                                <p className="text-white text-[16px] min-w-[115px] ff-cp--bold">
                                                    Nombre de usuario
                                                </p>
                                                <div className="relative bg-[#073147] min-w-[310px] max-w-[310px] h-[32px] flex items-center ff-cp--bold text-[12px] text-[#07DACB] border-[1.5px] border-[#07DACB] rounded-[10px]">
                                                    <img
                                                    src={iconSelector}
                                                    alt=""
                                                    className="w-[19px] h-[19px] absolute top-0 bottom-0 right-[7px] my-auto"
                                                    />
                                                    <Dropdown
                                                    mode="SIMPLE"
                                                    items={userByGroup}
                                                    attribute="fullname"
                                                    callback={setFormikUser}
                                                    placeholder="Escribe el nombre aquí"
                                                    name="users"
                                                    identifier="user_group"
                                                    allowAdd={true}
                                                    clear={clearFormikUser}
                                                    />
                                                </div>
                                            </div>
                                            <div className="flex justify-end">
                                                <button type="button" onClick={() => exportGroupUserReport() } disabled={value2 === undefined || formikUserGroup.length === 0 } className="ff-cp--bold text-[14px] text-[#ffffff]  w-[167px] h-[36px] bg-cover bg-center bg-no-repeat opacity-80 hover:opacity-100 hover:scale-110 flex items-center justify-center gap-[7px] disabled:opacity-40" style={{ backgroundImage: `url(${btnExport})` }}>Generar Reporte <div className="h-[17px] w-[12px] bg-cover bg-no-repeat bg-center" style={{backgroundImage: `url(${iconReport})`}}></div></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default DashboardSuperadmin;