import { TColor } from '../../type/color';
import SERVICES, { IServiceProps } from './serviceDummyData';

export interface IUserProps {
	id: string;
	username: string;
	name: string;
	surname: string;
	position: string;
	email?: string;
	src: string;
	srcSet: string;
	isOnline: boolean;
	isReply?: boolean;
	color: TColor;
	fullImage?: string;
	services?: IServiceProps[];
	password: string;
}

const john: IUserProps = {
    id: '1',
    username: 'superadmin@test.com',
    name: 'John',
    surname: 'Doe',
    position: 'CEO, Founder',
    email: 'superadmin@test.com',
    isOnline: true,
    isReply: true,
    color: 'primary',
    services: [SERVICES.SURFING, SERVICES.KITE_SURFING, SERVICES.TENNIS],
    password: 'Password!!!',
    src: '',
    srcSet: ''
};


const USERS: { [key: string]: IUserProps } = {
	JOHN: john
};

export const ROLE: string = "3d1ee2af-cc28-4615-891f-cd71530975fc";

export const ROLEG: string = "7b612f56-b0f5-42ed-9237-4f27b15af5a4";

export function getUserDataWithUsername(username: string): IUserProps {
	// @ts-ignore
	return USERS[Object.keys(USERS).filter((f) => USERS[f].username.toString() === username)];
}

export function getUserDataWithId(id?: string): IUserProps {
	// @ts-ignore
	return USERS[Object.keys(USERS).filter((f) => USERS[f].id.toString() === id.toString())];
}

export default USERS;
