import { Link } from 'react-router-dom'

// Components
import PageTitle from '../../../components/shared/PageTitle';
import ButtonRedirect from '../../../components/shared/ButtonRedirect';

// Images
import btnBack from '../../../assets/images/icons/icon-back.svg'

const RecoveryLinkExpired = () => {
  return (
    <section className="recovery-page">
      <div className="grid grid-cols-5 grid-rows-1 w-screen h-screen">
        <ButtonRedirect action="return" linkReturn="/login" text="return"></ButtonRedirect>
        <PageTitle 
          title={{ text: "El link ha expirado", color: "#60DFFF"}} 
          subtitle={{text: "Intenta ingresar de nuevo al portal.", size:"text-[16px]"}} 
          useLink={true}
          link={<div className="mt-3"><Link to="/login" className="text-[16px] text-[#7F29FE] flex gap-2">
          <img src={btnBack} alt="" className="w-[16px] h-[16px] mt-1" /> Regresar al log in</Link></div>}/>
      </div>
    </section>
  );
}

export default RecoveryLinkExpired;