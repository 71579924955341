import { useState } from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useDispatch } from "react-redux"
import { useNavigate, Link } from 'react-router-dom';

// Services
import { authLogin } from '../../services/apis/auth';

// Components
import ButtonRedirect from '../../components/shared/ButtonRedirect';
import ErrorMessage from '../../components/shared/ErrorMessage';
import PageTitle from '../../components/shared/PageTitle';

// Constants
import { MESSAGES } from '../../constants/response_message';
import { MessageResult } from '../../models/MessageResult'

// Images
import iconLock from '../../assets/images/icons/icon-lock.svg'
import iconEmail from '../../assets/images/icons/icon-email.svg'
import { LOGIN_FAIL, LOGIN_SUCCESS } from '../../actions/type';

const Login = () => {

  const [message, setMessage] = useState(new MessageResult(false, false, ''));
  const [isLoading, setIsLoading] = useState(false);
 
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const validateSchema = Yup.object().shape({
    email: Yup.string().email(MESSAGES.REGISTER.WRONG.EMAIL.PATH).required(MESSAGES.REGISTER.WRONG.EMPTY),
    password: Yup.string().required(MESSAGES.REGISTER.WRONG.EMPTY)
  })

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validateSchema,
    initialValues: {
      email: '',
      password: ''
    },
    onSubmit: async (values) => {
        setIsLoading(true);
        await authLogin(values).then(data => {
          if (data.status) {
            dispatch({ type: LOGIN_SUCCESS, payload: data.access_token });
            
            navigate(data.page)
          }
          else {
            dispatch({ type: LOGIN_FAIL });
            setMessage({isView: true, status: false, message: data.message});
          }
        })
        .catch((err) => console.log(err))
        setIsLoading(false);
    },
    validate: values => {
      validateSchema.validate(values).catch( err => {
        const error: string = err.errors[0];
        setMessage({isView: true, status: false, message: error});
      })
    }
  });

  return (
    <section className="login-page">
      <div className="grid grid-cols-5 grid-rows-1 w-screen h-screen">
        <ButtonRedirect action="return" linkReturn="/" text="return"></ButtonRedirect>
        <PageTitle title={{ text: "M.O.V.E Match", color: "#60DFFF"}} subtitle={{text: "Ingresa a la vista de administrador de M.O.V.E y encuentra toda la información que necesitas.", size: "text-[16px]"}}></PageTitle>
        <div className="grid min-h-full col-span-3 self-center place-items-center">
          <form onSubmit={formik.handleSubmit} className="form-signin w-full max-w-[470px] grid self-center items-center">
            <div className="form-floating mb-4">
              <div className="grid mb-4">
                <label className="ff-m--regular text-[16px] text-[#60DFFF] mb-1" htmlFor="email">Correo electrónico</label>
                <div className="relative">
                  <span className="absolute block top-0 bottom-0 left-[13px] m-auto w-[22px] h-[22px] pointer-events-none bg-cover bg-center" style={{ backgroundImage: `url(${iconEmail})` }}></span>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    className="w-full bg-transparent border border-solid border-[#FFFFFF] color-white rounded-xl leading-10 pl-[45px]"
                    onChange={formik.handleChange}
                    onPaste={formik.handleChange}
                    value={formik.values.email}
                    placeholder="abc@gmail.com"
                  />
                </div>
              </div>

              <div className="grid">
                <label className="ff-m--regular text-[16px]  text-[#60DFFF] mb-1" htmlFor="password">Contraseña</label>
                <div className="relative">
                  <span className="absolute block top-0 bottom-0 left-[13px] m-auto w-[22px] h-[22px] pointer-events-none bg-cover bg-center" style={{ backgroundImage: `url(${iconLock})` }}></span>
                  <input
                    id="password"
                    name="password"
                    type="password"
                    className="w-full bg-transparent border border-solid border-[#FFFFFF] color-white rounded-xl leading-10 pl-[45px]"
                    onChange={formik.handleChange}
                    onPaste={e => e.preventDefault()}
                    value={formik.values.password}
                    placeholder="********"
                  />
                </div>
              </div>
              <div className="checkbox mb-3 mt-3 text-right">
                <label>
                  <Link to="/recovery/reset-password" className="text-[18px] text-[#60DFFF]"> Recuperar contraseña</Link>
                </label>
              </div>
              {message.isView && !message.status && (
                <div className="mb-4 mt-4">
                  <ErrorMessage title={message.message}></ErrorMessage>
                </div>
              )}   
              <div className="flex justify-center mt-6">
                <ButtonRedirect type="submit" text="Ingresar" next={true} loading={isLoading}></ButtonRedirect>
              </div>

            </div>
            
          </form>
        </div>
      </div>
    </section>
  );
}

export default Login;