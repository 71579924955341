import { useState, useEffect } from "react";
import { toast } from "react-hot-toast";

// Services
import { createOrUpdateVariables, getAllByRazon } from "../../services/apis/variables";

// Components
import DashboardSidebar from "../../components/dashboard/DashboardSidebar";

// Constants
import { CAREER_CLASSIFICATION } from "../../constants/classification";

// Methods
import { getRole, getUser } from "../../common/methods/storage";

// Images
import iconLogo from '../../assets/images/icons/icon-logo.svg'
import btnExport from "../../assets/images/buttons/btn-export.svg";
import btnArrowUpSection from "../../assets/images/buttons/btn-arrow-up-section.svg";
import btnArrowDownSection from "../../assets/images/buttons/btn-arrow-down-section.svg";

const FormulaSuperadmin = () => {

    const role = getRole();
    const user = getUser();

    const [conditional1Variable, setConditional1Variable] = useState<any>(undefined)
    const [conditional2Variable, setConditional2Variable] = useState<any>(undefined)
    const [profileVariable, setProfileVariable] = useState<any>(undefined)

    const [idealVariable, setIdealVariable] = useState<any>(undefined)
    const [optimaVariable, setOptimaVariable] = useState<any>(undefined)
    const [complementVariable, setComplementVariable] = useState<any>(undefined)
    const [ambiguousVariable, setAmbiguousVariable] = useState<any>(undefined)
    const [defiantVariable, setDefiantVariable] = useState<any>(undefined)

    const [showVerbFormula, setShowVerbFormula] = useState<boolean>(false);
    const [showProfileValue, setShowProfileValue] = useState<boolean>(false);
    const [showCareerFormula, setShowCareerFormula] = useState<boolean>(false);

    useEffect( () => { getAllVariables() }, [])

    const getAllVariables = async () => {
        await getAllByRazon('VERBS').then( (variables: any[]) => {
            variables.forEach( variable => {
                if(variable.name === 'CONDITION_1') setConditional1Variable(variable)
                else if(variable.name === 'CONDITION_2') setConditional2Variable(variable)
                else if(variable.name === 'PROFILE_VALUE') setProfileVariable(variable)
            })
        })
        await getAllByRazon('CAREER_CALCULATION').then( (variables: any[]) => {
            variables.forEach( variable => {
                if(variable.name === CAREER_CLASSIFICATION.IDEAL) setIdealVariable(variable)
                else if(variable.name === CAREER_CLASSIFICATION.OPTIMA) setOptimaVariable(variable)
                else if(variable.name === CAREER_CLASSIFICATION.COMPLEMENT) setComplementVariable(variable)
                else if(variable.name === CAREER_CLASSIFICATION.AMBIGUOUS) setAmbiguousVariable(variable)
                else if(variable.name === CAREER_CLASSIFICATION.DEFIANT) setDefiantVariable(variable)
            })
        })
    }

    const updateVerbVariables = async () => {
        await createOrUpdateVariables([conditional1Variable, conditional2Variable])
            .then( data => {
                toast.dismiss()
                if(data.status) toast.success(data.message)
                else toast.error(data.message)
            }) 
    }

    const updateProfileVariables = async () => {
        await createOrUpdateVariables([profileVariable])
            .then( data => {
                toast.dismiss()
                if(data.status) toast.success(data.message)
                else toast.error(data.message)
            }) 
    }

    const updateCareerClassificationVariables = async () => {
        await createOrUpdateVariables([idealVariable, optimaVariable, complementVariable, ambiguousVariable, defiantVariable])
            .then( data => {
                toast.dismiss()
                if(data.status) toast.success(data.message)
                else toast.error(data.message)
            }) 
    }

    return(
        <div className="h-screen w-screen overflow-hidden">
            <div className="h-full w-full p-[24px] grid grid-cols-[44px_auto] grid-rows-1 gap-[24px]">
                <div className="col-[1_/_2] row-1">
                    <DashboardSidebar current={3} role={role} />
                </div>
                <div className={`col-[2_/_2] min-h-full min-w-full row-1 gap-7 h-full grid overflow-auto py-[15px] ${!showVerbFormula && !showProfileValue && !showCareerFormula ? 'grid-rows-[repeat(7,minmax(0,1fr))]' : ''}`}>
                    <div className="col-span-1 row-[1_/_2] w-3/5 h-[68px]">
                        <div className="relative h-full border-[#60DFFF] bg-gradient-to-b from-[#051d3e] border-2 rounded-[20px]">
                            <span className="absolute top-[-16px] left-[24px] w-[160px] h-[32px] bg-[#07DBCB] text-[14px] text-[#000000] rounded-[20px] p-[6px] pl-[14px] leading-normal ff-cp--bold">Administrador</span>
                            <div className="h-[64px] pl-[38px] pr-[24px] pt-[16px] pb-[9px] flex justify-between items-center rounded-t-[20px] bg-gradient-to-b from-[#043658] to-transparent">
                                <h2 className="ff-cp--bold text-[#ffffff] text-[24px]">M.O.V.E Match</h2>
                                <div className="flex items-center">
                                    <h3 className="ff-cp--bold text-[16px] text-[#07DACB] leading-none mr-[32px]">{user?.nickname}</h3>
                                    <img src={iconLogo} alt="" className="w-[34px] h-[34px]" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <section className={`col-span-1 ${showVerbFormula ? 'row-span-2' : 'row-span-1 my-4'} min-w-full`}>
                        <div className={`${showVerbFormula ? 'h-full border-2' : 'h-0 border-[1px]'} relative border-[#60DFFF] rounded-[20px] max-h-[190px]`}>
                            <div className="relative h-full rounded-[20px]">
                                <span className="absolute top-[-16px] left-[25px] w-[180px] h-[32px] bg-[#07DBCB] text-[14px] text-[#000000] rounded-[20px] p-[6px] pl-[14px] pr-[14px] leading-normal ff-cp--bold z-20">
                                    PERFIL VOCACIONAL
                                </span>
                                <div onClick={() => setShowVerbFormula(!showVerbFormula)} className="w-[32px] h-[32px] absolute -top-[16px] left-[220px] bg-cover bg-center bg-no-repeat cursor-pointer hover:scale-110" style={{ backgroundImage: `url(${showVerbFormula ? btnArrowUpSection : btnArrowDownSection})`}}></div>
                                {
                                    showVerbFormula && 
                                    <div className="overflow-hidden h-full pt-[43px] px-[30px] pb-[24px] rounded-[20px] bg-gradient-to-b from-[#051d3e]">
                                        <p className="text-[13px] text-white leading-none ff-cp--regular mb-[40px]">
                                            Aquí se mide el % de respuestas para asignarle un pefil. Por ejemplo, si un perfil alcanza el 50% solo se toma en cuenta ese perfil (condición 1). Si ninguno alcanza el 50%, entonces solo te toman en cuenta los que tienen mayor o igual al 25% (condición 2.)
                                        </p>
                                        <div className="w-full grid grid-cols-[200px_auto_200px] gap-2">
                                            <div className="col-[1/2] self-end py-2">
                                                <span className="ff-cp--bold text-[16px] text-white">Condiciones</span>
                                            </div>
                                            <div className="col-[2/2] flex gap-5">
                                                <div className="grid gap-1">
                                                    <span className="text-[12px] text-white">Condición 1</span>
                                                    <input value={conditional1Variable.value} 
                                                           onChange={(e) => setConditional1Variable({...conditional1Variable, value: e.target.value})} 
                                                           className="bg-[#073147] h-[32px] w-[150px] border-solid border-[1px] border-[#07DACB] rounded-[10px] px-2 text-[12px] text-[#07DACB]"></input>
                                                </div>
                                                <div className="grid gap-1">
                                                    <span className="text-[12px] text-white">Condición 2</span>
                                                    <input value={conditional2Variable.value} 
                                                           onChange={(e) => setConditional2Variable({...conditional2Variable, value: e.target.value})} 
                                                           className="bg-[#073147] h-[32px] w-[150px] border-solid border-[1px] border-[#07DACB] rounded-[10px] px-2 text-[12px] text-[#07DACB]"></input>
                                                </div>
                                            </div>
                                            <div>
                                                <button
                                                    onClick={updateVerbVariables}
                                                    type="submit"
                                                    className="ff-cp--bold text-[14px] text-[#ffffff] w-[167px] h-[36px] bg-cover bg-no-repeat bg-center opacity-80 hover:opacity-100 hover:scale-110 disabled:opacity-50"
                                                    style={{ backgroundImage: `url(${btnExport})` }}>
                                                    Actualizar
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </section>
                    <section className={`col-span-1 ${showProfileValue ? 'row-span-2' : 'row-span-1 my-4'} min-w-full`}>    
                        <div className={`${showProfileValue ? 'h-full border-2' : 'h-0 border-[1px]'} relative border-[#60DFFF] rounded-[20px] max-h-[160px]`}>
                            <div className="relative h-full rounded-[20px]">
                            <span className="absolute top-[-16px] left-[25px] w-[180px] h-[32px] bg-[#07DBCB] text-[14px] text-[#000000] rounded-[20px] p-[6px] pl-[14px] pr-[14px] leading-normal ff-cp--bold z-20">
                                VALOR POR PERFIL
                            </span>
                            <div onClick={() => setShowProfileValue(!showProfileValue)} className="w-[32px] h-[32px] absolute -top-[16px] left-[220px] bg-cover bg-center bg-no-repeat cursor-pointer hover:scale-110" style={{ backgroundImage: `url(${showProfileValue ? btnArrowUpSection : btnArrowDownSection})`}}></div>
                            {
                                showProfileValue && 
                                <div className="overflow-hidden h-full pt-[43px] px-[30px] pb-[24px] rounded-[20px] bg-gradient-to-b from-[#051d3e]">
                                    <p className="text-[13px] text-white leading-none ff-cp--regular mb-[40px]">
                                        Aquí se cambia el valor numérico de la respuesta en al pantalla se selección de perfil. Todas las la demas pantallas otorgan un valor de 4 (más importante) o cero (no importante) según la selección del usuario.
                                    </p>
                                    <div className="w-full grid grid-cols-[200px_auto_200px] gap-2">
                                        <div className="col-[1/2] self-end py-2">
                                            <span className="ff-cp--bold text-[16px] text-white">Valor numérico</span>
                                        </div>
                                        <div>
                                            <input value={profileVariable.value} onChange={(e) => setProfileVariable({...profileVariable, value: e.target.value})} className="bg-[#073147] h-[32px] w-[150px] border-solid border-[1px] border-[#07DACB] rounded-[10px] px-2 text-[12px] text-[#07DACB]"></input>
                                        </div>
                                        <div>
                                            <button
                                                onClick={updateProfileVariables}
                                                type="submit"
                                                className="ff-cp--bold text-[14px] text-[#ffffff] w-[167px] h-[36px] bg-cover bg-no-repeat bg-center opacity-80 hover:opacity-100 hover:scale-110 disabled:opacity-50"
                                                style={{ backgroundImage: `url(${btnExport})` }}>
                                                Actualizar
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            }
                            </div>
                        </div>
                    </section>
                    <section className={`col-span-1 ${showCareerFormula ? 'row-span-2' : 'row-span-1 my-4'} min-w-full`}>
                        <div className={`${showCareerFormula ? 'h-full border-2' : 'h-0 border-[1px]'} relative border-[#60DFFF] rounded-[20px] max-h-[250px]`}>
                            <div className="relative h-full rounded-[20px]">
                            <span className="absolute top-[-16px] left-[25px] w-[220px] h-[32px] bg-[#07DBCB] text-[14px] text-[#000000] rounded-[20px] p-[6px] pl-[14px] pr-[14px] leading-normal ff-cp--bold z-20">
                                VARIABLES DE CATEGORÍAS
                            </span>
                            <div onClick={() => setShowCareerFormula(!showCareerFormula)} className="w-[32px] h-[32px] absolute -top-[16px] left-[260px] bg-cover bg-center bg-no-repeat cursor-pointer hover:scale-110" style={{ backgroundImage: `url(${showCareerFormula ? btnArrowUpSection : btnArrowDownSection})`}}></div>
                            {
                                showCareerFormula && 
                                <div className="overflow-hidden h-full pt-[43px] px-[30px] pb-[24px] rounded-[20px] bg-gradient-to-b from-[#051d3e]">
                                    <p className="text-[13px] text-white leading-none ff-cp--regular mb-[40px]">
                                        Colocar los margenes para cada categoria de acuerdo al % obtenido de las respuesta       s de el test (talentos, sustantivos, etc).
                                    </p>
                                    <section className="grid gap-5">
                                        <div className="grid grid-cols-3 gap-12">
                                            <div className="flex place-content-between">
                                                <div className="col-[1/2] self-end py-2">
                                                    <span className="ff-cp--bold text-[16px] text-white">Ideales</span>
                                                </div>
                                                <div className="grid gap-1">
                                                    <span className="text-[12px] text-white">Márgenes</span>
                                                    <input value={idealVariable.value} 
                                                           onChange={(e) => setIdealVariable({...idealVariable, value: e.target.value})} 
                                                           className="bg-[#073147] h-[32px] w-[150px] border-solid border-[1px] border-[#07DACB] rounded-[10px] px-2 text-[12px] text-[#07DACB]"></input>
                                                </div>
                                            </div>
                                            <div className="flex place-content-between">
                                                <div className="col-[1/2] self-end py-2">
                                                    <span className="ff-cp--bold text-[16px] text-white">Complementarias</span>
                                                </div>
                                                <div className="grid gap-1">
                                                    <span className="text-[12px] text-white">Márgenes</span>
                                                    <input value={complementVariable.value} 
                                                           onChange={(e) => setComplementVariable({...complementVariable, value: e.target.value})} 
                                                           className="bg-[#073147] h-[32px] w-[150px] border-solid border-[1px] border-[#07DACB] rounded-[10px] px-2 text-[12px] text-[#07DACB]"></input>
                                                </div>
                                            </div>
                                            <div className="flex place-content-between">
                                                <div className="col-[1/2] self-end py-2">
                                                    <span className="ff-cp--bold text-[16px] text-white">No recomendables</span>
                                                </div>
                                                <div className="grid gap-1">
                                                    <span className="text-[12px] text-white">Peso</span>
                                                    <input value={defiantVariable.value} 
                                                           onChange={(e) => setDefiantVariable({...defiantVariable, value: e.target.value})} 
                                                           className="bg-[#073147] h-[32px] w-[150px] border-solid border-[1px] border-[#07DACB] rounded-[10px] px-2 text-[12px] text-[#07DACB]"></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="grid grid-cols-3 gap-12">
                                            <div className="flex place-content-between">
                                                <div className="col-[1/2] self-end py-2">
                                                    <span className="ff-cp--bold text-[16px] text-white">Óptimas</span>
                                                </div>
                                                <div className="grid gap-1">
                                                    <span className="text-[12px] text-white">Márgenes</span>
                                                    <input value={optimaVariable.value} 
                                                           onChange={(e) => setOptimaVariable({...optimaVariable, value: e.target.value})} 
                                                           className="bg-[#073147] h-[32px] w-[150px] border-solid border-[1px] border-[#07DACB] rounded-[10px] px-2 text-[12px] text-[#07DACB]"></input>
                                                </div>
                                            </div>
                                            <div className="flex place-content-between">
                                                <div className="col-[1/2] self-end py-2">
                                                    <span className="ff-cp--bold text-[16px] text-white">Ambiguas</span>
                                                </div>
                                                <div className="grid gap-1">
                                                    <span className="text-[12px] text-white">Márgenes</span>
                                                    <input value={ambiguousVariable.value} 
                                                           onChange={(e) => setAmbiguousVariable({...ambiguousVariable, value: e.target.value})} 
                                                           className="bg-[#073147] h-[32px] w-[150px] border-solid border-[1px] border-[#07DACB] rounded-[10px] px-2 text-[12px] text-[#07DACB]"></input>
                                                </div>
                                            </div>
                                            <div className="self-end justify-self-end">
                                                <button
                                                    onClick={updateCareerClassificationVariables}
                                                    type="submit"
                                                    className="ff-cp--bold text-[14px] text-[#ffffff] w-[167px] h-[36px] bg-cover bg-no-repeat bg-center opacity-80 hover:opacity-100 hover:scale-110 disabled:opacity-50"
                                                    style={{ backgroundImage: `url(${btnExport})` }}>
                                                    Actualizar
                                                </button>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            }
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default FormulaSuperadmin;