export const API_ROUTES = {
    'MAIL': '/mail/send',
    'USER': {
        'CREATE_ADMIN': '/users/createAdminUser',
        'GET': {
            'ALL': '/users/',
            'ALL_ADMIN': '/users/getAllAdmins',
            'BY': {
                'GROUP': '/users/getAllByGroupId/'
            }
        },
        'UPDATE': {
            'TUTORIAL': '/users/setStatusTutorial/'
        }
    },
    'AUTH': {
        'LOGIN': '/authAdmin/loginAdmin',
        'REGISTER': '/authAdmin/registerAdmin',
        'LOGOUT':  '/authAdmin/logout',
        'FORGOT': {
            'PASSWORD': '/auth/forgotPassword',
            'RESET': '/auth/resetPassword'
        }
    },
    'RECORD': {
        'CREATE': '/records',
        'UPDATE': '/records/',
        'GET': {
            'CURRENT': '/records/getCurrent',
            'VOCATIONAL': '/records/getVocationalProfile/',
            'BY': {
                'ID': '/records/'
            }
        }

    },
    'WORKSPACE': {
        'GET': {
            'ALL': '/workspaces'
        },
        'CREATE': '/workspaces',
        'UPDATE': '/workspaces/'
    },
    'ACCESS_PASSES': {
        'CREATE': '/accessPasses',
        'UPDATE': '/accessPasses/',
        'DELETE': '/accessPasses/',
        'GET': {
            'BY': {
                'ID': '/accessPasses/',
                'NAME': '/accessPasses/',
            },
            'ALL': {
                'ONLY': '/accessPasses',
                'BY': {
                    'WORKSPACE': {
                        'ID': '/accessPasses/getAllByWorkspaceId/',
                        'NAME': '/accessPasses/getAllByWorkspaceName/'
                    },
                    'USER': '/accessPasses/getAllByUserId/'
                }
            }
        }
    },
    'ACCESS_PASSES_USER': {
        'CREATE': '/accessPassesUser',
        'UPDATE': '/accessPassesUser/',
        'UPDATE_LIST': '/accessPassesUser/updateOrCreateAccess',
        'DELETE': '/accessPassesUser/',
        'GET': {
            'BY': {
                'ID': '/accessPassesUser/',
                'NAME': '/accessPassesUser/',
            },
            'ALL': {
                'ONLY': '/accessPassesUser',
                'BY': {
                    'ACCESS_PASSES': '/accessPassesUser/getAllByAccessPassesId/'
                }
            }
        }
    },
    'VARIABLE': {
        'CREATE':'/variables',
        'CREATE_OR_UPDATE': {
            'ONLY': '/variables/createOrUpdate',
            'LIST': '/variables/createOrUpdateList'
        },
        'GET': {
            'ALL': {
                'BY': {
                    'RAZON': '/variables/razon/'
                }
            }
        }
    }

}

// 'FORGOT': {
//     'PASSWORD': '/authAdmin/forgotPassword',
//     'RESET': '/authAdmin/resetPassword'
// }