import { roles } from "../../constants/lists/role";

export const getUser = () => {
    const user = localStorage.getItem("user");
    if(user) return JSON.parse(user);
    return { id: '' }
}

export const getRecord = () => {
    const record = localStorage.getItem("current_record");
    if(record) return JSON.parse(record);
    return { id: '' }
}

export const getToken = () => {
    const token = localStorage.getItem("access_token")
    return token ?? null;
}

export const getRole = () => {
    const user = localStorage.getItem("user");
    if(user) {
        const json_user = JSON.parse(user);
        const role = roles.find( rol => rol.id === json_user.role_id)
        return role?.name
    }
    return roles[1].name
}