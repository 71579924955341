import { Link, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Services
import { logoutAdmin } from '../../services/apis/auth';

// Actions
import { LOGOUT } from '../../actions/type';

// Images
import btnSidebarActive from '../../assets/images/buttons/btn-sidebar-active.svg'

import adminDashboard from '../../assets/images/icons/icon-dashboard.svg'
import accessDashboard from '../../assets/images/icons/access_dashboard.svg'
import uploadDashboard from '../../assets/images/icons/upload_dashboard.svg'
import formulaIcon from '../../assets/images/icons/formula-icon.svg'

import iconDashboardActive from '../../assets/images/icons/icon-dashboard-active.svg'
import accessDashboardActive from '../../assets/images/icons/access_dashboard-active.svg'

import logoSearch from '../../assets/images/logos/logo.svg'
import logout from '../../assets/images/icons/logout.svg'

interface SidebarProps {
    current: number,
    role?: string
}

const  DashboardSidebar = ({current = 0, role = 'SUPERADMIN'}: SidebarProps) => {

    const dispatch = useDispatch();
    const navigate = useNavigate()

    const logoutUser = async () => {
        await logoutAdmin()
        .then( data => {
            if(data.status) {
                localStorage.clear()
                dispatch({ type: LOGOUT })
                navigate('/login')
            }
        })       
      }

    return (
        <div className="w-[48px] h-full relative flex flex-col items-center justify-between z-[100]">
            <div className="h-[50px]">
                <Link to="#" className="block">
                    <img src={logoSearch} alt="" />
                </Link>
            </div>
            <div className={`flex flex-col w-[48px] items-center h-[calc(100%-150px)] ${ role === 'SUPERADMIN' ? 'border-[#60DFFF] border-2 rounded-[20px]' : 'border-none'}  place-content-center`}>
                {
                    role === 'SUPERADMIN' &&
                    <nav className="w-[32px] flex flex-col">
                        <Link to={'/superadmin/dashboard'} className={`block h-[32px] ${current === 0? 'w-[32px] bg-cover opacity-100' : 'w-[32px] bg-center opacity-70 hover:opacity-100'} my-[16px] bg-no-repeat`} style={{ backgroundImage: `url(${current === 0 ? iconDashboardActive : adminDashboard})` }}/>
                        <Link to={'/superadmin/access'} className={`block h-[32px] ${current === 1? 'w-[32px] bg-cover opacity-100' : 'w-[32px] bg-center opacity-70 hover:opacity-100'} menu-mesh-step my-[16px] bg-no-repeat`} style={{ backgroundImage: `url(${current === 1 ? accessDashboardActive : accessDashboard})` }}/>
                        <Link to={'/superadmin/import'} className={`block h-[32px] ${current === 2? 'w-[52px] bg-cover opacity-100' : 'w-[32px] bg-center opacity-70 hover:opacity-100'} menu-mesh-step my-[16px] bg-no-repeat`} style={{ backgroundImage: `url(${current === 2 ? btnSidebarActive : uploadDashboard})` }}/>
                        <Link to={'/superadmin/formula'} className={`block h-[32px] ${current === 3? 'w-[52px] bg-cover opacity-100' : 'w-[32px] bg-center opacity-70 hover:opacity-100'} menu-mesh-step my-[16px] bg-no-repeat`} style={{ backgroundImage: `url(${current === 3 ? btnSidebarActive : formulaIcon})` }}/>
                    </nav>
                }
                {
                    role === 'ADMIN' &&
                    <nav className="w-[32px] flex flex-col">
                        <Link to={'/admin/dashboard'} className={`block h-[32px] ${current === 0? 'w-[32px] bg-cover opacity-100' : 'w-[32px] bg-center opacity-70 hover:opacity-100'} my-[16px] bg-no-repeat`} style={{ backgroundImage: `url(${current === 0 ? iconDashboardActive : adminDashboard})` }}/>
                        <Link to={'/admin/link'} className={`block h-[32px] ${current === 1? 'w-[32px] bg-cover opacity-100' : 'w-[32px] bg-center opacity-70 hover:opacity-100'} menu-mesh-step my-[16px] bg-no-repeat`} style={{ backgroundImage: `url(${current === 1 ? accessDashboardActive : accessDashboard})` }}/>
                    </nav>
                }
            </div>
            <div onClick={logoutUser} className={`block h-[62px] cursor-pointer w-[62px] bg-center menu-mesh-step bg-no-repeat opacity-70 hover:opacity-100`} style={{ backgroundImage: `url(${logout})` }}></div>
        </div>
    );
}

export default DashboardSidebar;