import { API_ROUTES } from './../../constants/api_routes';
import { HEADERS } from '../../constants/connection';
import { getUser } from '../../common/methods/storage';

export const createUserAdmin = async (data: any) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.USER.CREATE_ADMIN}`, {
        method: 'POST',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
        .then(data => {
            return data;
        })
    .catch(error => {
        return false
    });
}

export const getAllUsers = async (role: string) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.USER.GET.ALL}?role=${role}`, {
        method: 'GET',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
    })
    .then((response) => response.json())
        .then(data => {
            return data;
        })
    .catch(error => {
        return []
    });
}

export const getAllUsersByGroup = async (group_id: string) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.USER.GET.BY.GROUP}${group_id}`, {
        method: 'GET',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
    })
    .then((response) => response.json())
        .then(data => {
            if(data.status) return data.users
            else return []
        })
    .catch(error => {
        return []
    });
}

export const getAllAdminUsers = async () => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.USER.GET.ALL_ADMIN}`, {
        method: 'GET',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
    })
    .then((response) => response.json())
        .then(data => {
            return data;
        })
    .catch(error => {
        return false
    });
}

export const setUserTutorial = async (status: boolean) => {
    const user = getUser();
    const body = {
        status: status
    };
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.USER.UPDATE.TUTORIAL}${user.id}`, {
        method: 'PATCH',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        body: JSON.stringify(body)
    })
    .then((response) => response.json())
        .then(data => {
            if(data.status) localStorage.setItem("tutorial", `${status}`)
        })
    .catch(error => {
        return false
    });
}