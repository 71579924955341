import * as React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Services
import { forgotPassword } from '../../../services/apis/auth';

// Models
import { MessageResult } from '../../../models/MessageResult';

// Components
import PageTitle from '../../../components/shared/PageTitle';
import ErrorMessage from '../../../components/shared/ErrorMessage';
import ButtonRedirect from '../../../components/shared/ButtonRedirect';

// Constants
import { MESSAGES } from '../../../constants/response_message';

// Images
import btnBack from '../../../assets/images/icons/icon-back.svg'
import iconEmail from '../../../assets/images/icons/icon-email.svg'

const RecoveryResetPassword = () => {

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = React.useState(false);
  const [message, setMessage] = React.useState(new MessageResult(false, false, ''));

  const validateSchema = Yup.object().shape({
    email: Yup.string().email(MESSAGES.REGISTER.WRONG.EMAIL.PATH).required(MESSAGES.REGISTER.WRONG.EMPTY),
  })

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validateSchema,
    initialValues: {
      email: '',
      role: 'ADMIN'
    },
    onSubmit: async (values) => {
        setIsLoading(true);
        await forgotPassword(values).then(data => {
          if (data.status) {
            if (data.status) navigate('/recovery/send-message', { state: { email: values.email, token: data.recovery_token } })
          }
          else setMessage({ isView: true, status: data.status, message: data.message })
        })
        .catch(err => console.log(err))
        setIsLoading(false);
    },
    validate: values => {
      validateSchema.validate(values).catch( err => {
        const error: string = err.errors[0];
        setMessage({isView: true, status: false, message: error});
      })
    }
  });

  return (
    <section className="recovery-page">
      <div className="grid grid-cols-5 grid-rows-1 w-screen h-screen">
        <ButtonRedirect action="return" linkReturn="/login" text="return"></ButtonRedirect>
        <PageTitle
          title={{ text: "Recuperar Contraseña", color: "#60DFFF" }}
          subtitle={{ text: "Ingresa tu correo para recuperar tu contraseña.", size: "text-[16px]" }}
          useLink={true}
          link={<div className="mt-3"><Link to="/login" className="text-[16px] text-[#7F29FE] flex gap-2">
            <img src={btnBack} alt="" className="w-[16px] h-[16px] mt-1" />  Regresar al log in</Link></div>} />
        <div className="grid col-span-3 self-center place-items-center">
          <form onSubmit={formik.handleSubmit} className="form-signin w-100 grid items-center self-center">
            <div className="form-floating mb-4">
              <div className="grid mb-4">
                <label className="ff-m--regular text-[16px]  text-[#60DFFF] mb-1" htmlFor="email">Ingrese su correo electrónico</label>
                <div className="relative">
                  <span className="absolute block top-0 bottom-0 left-[13px] m-auto w-[22px] h-[22px] pointer-events-none bg-cover bg-center" style={{ backgroundImage: `url(${iconEmail})` }}></span>
                  <input
                    id="email"
                    name="email"
                    type="email"
                    className="w-full bg-transparent border border-solid border-[#FFFFFF] color-white rounded-xl leading-10 pl-[45px]"
                    onChange={formik.handleChange}
                    onPaste={e => e.preventDefault()}
                    value={formik.values.email}
                    placeholder="name@example.com"
                  />
                </div>
              </div>
              <div className="mt-5">
                {message.isView && !message.status && <ErrorMessage title={message.message}></ErrorMessage>}
              </div>
            </div>
            <ButtonRedirect type="submit" text="Recuperar" next={true} loading={isLoading}></ButtonRedirect>
          </form>
        </div>
      </div>
    </section>
  );
}

export default RecoveryResetPassword;