import { API_ROUTES } from './../../constants/api_routes';
import { HEADERS } from '../../constants/connection';
import { getUser } from '../../common/methods/storage';

export const createVariable = async (data: any) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.VARIABLE.CREATE}`, {
        method: 'POST',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
        .then(data =>  data.status ? data.variable : null )
    .catch(error => null );
}

export const createOrUpdateVariable = async (data: any) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.VARIABLE.CREATE_OR_UPDATE.ONLY}`, {
        method: 'POST',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
        .then(data =>  data.status ? data.variable : null )
    .catch(error => null );
}

export const getAllByRazon = async (razon: string) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.VARIABLE.GET.ALL.BY.RAZON}${razon}`, {
        method: 'GET',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
    })
    .then((response) => response.json())
        .then(data =>  {
            return data.status ? data.variables : []
        } )
    .catch(error => {
        return []
    } );
}

export const createOrUpdateVariables = async (variables: any[]) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.VARIABLE.CREATE_OR_UPDATE.LIST}`, {
        method: 'POST',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        body: JSON.stringify({ variables })
    })
    .then((response) => response.json())
        .then(data =>  {
            return data
        } )
    .catch(error => {
        return null
    } );
}