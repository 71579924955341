import { useState } from 'react';

import { useFormik } from 'formik';
import { toast } from "react-hot-toast";

// Services
import { downloadTemplate, saveTemplate } from "../../services/apis/admin";

// Constants
import { MESSAGES } from '../../constants/response_message';

// Images
import btnExport from '../../assets/images/buttons/btn-export.svg';
import iconLink from '../../assets/images/icons/icon-link.svg';
import iconLoading from '../../assets/images/icons/icon-spinner.svg';

interface ImportSectionProps {
    title: string,
    description: string,
    template: string,
    upload: string,
    template_name: string
}

const ImportSection = ({ title, description, template, upload, template_name }: ImportSectionProps) => {

    const [fileTemplate, setFileTemplate] = useState<any>(undefined)
    const [fileTemplateName, setFileTemplateName] = useState<any>('')
    const [loading, setLoading] = useState<boolean>(false)
    const [reset, setReset] = useState<boolean>(false)

    const templateCareers = async () => {
        setLoading(true)
        await downloadTemplate(template).then((blob: any) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Template ${template_name}.xlsx`);
            document.body.appendChild(link);
            link.click();
            setLoading(false)
        });
    };

    const formik = useFormik({
        validateOnChange: false,
        validateOnBlur: false,
        initialValues: {
          file: undefined,
        },
        onSubmit: async (values) => {
          toast.dismiss()
          if(fileTemplate) {
            setLoading(true)
            values.file = fileTemplate
            await saveTemplate(upload, values.file).then((data) => {
                if(data.status) {
                    setReset(true)
                    toast.success(data.message)
                    setFileTemplate(undefined)
                    setFileTemplateName('')
                    formik.resetForm()
                    setTimeout(() => {
                        setReset(false)
                        setLoading(false)
                    }, 400);
                }
                else {
                    // toast.error(MESSAGES.UPLOAD.WRONG.EMPY)
                    toast.error(data.error.message)
                }
            })
            .finally(() => setLoading(false))
          }
          else toast.error(MESSAGES.UPLOAD.WRONG.EMPY)
        },
      });

      const handleFileChange = (event: any) => {
        const reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        setFileTemplateName(event.target.files[0].name)
        reader.onload = () => setFileTemplate(reader.result)
      };

    return (
        <>
            {
                loading &&
                <div className="fixed bg-black/60 inset-0 grid place-content-center z-[100]">
                    <img src={iconLoading} width={80} height={80}></img>
                </div>
            }
            <div className="col-span-1 row-span-1 min-w-full h-[250px]">
                <div className="h-full relative border-[#60DFFF] border-2 rounded-[20px]">
                    <div className="relative h-full rounded-[20px]">
                        <span className="absolute top-[-16px] left-[25px] w-[216px] h-[32px] bg-[#07DBCB] text-[14px] text-[#000000] rounded-[20px] p-[6px] pl-[14px] leading-normal ff-cp--bold z-20">{title}</span>
                        <div className="overflow-hidden rounded-[20px] h-full pt-[43px] px-[30px] pb-[24px] bg-gradient-to-b from-[#051d3e]">
    
                            <p className="text-[12px] text-white leading-none mb-[40px]">{description}</p>
    
                            <form onSubmit={formik.handleSubmit}>
                                <div className="flex justify-between items-center mb-[14px]">
                                    <p className="text-white text-[18px] ff-cp--bold">Formato ejemplo</p>
                                    <div onClick={templateCareers} className="relative bg-[#073147] w-[310px] h-[32px] flex items-center ff-cp--bold text-[12px] text-[#07DACB] border-[1.5px] pl-[14px] border-[#07DACB] rounded-[10px] cursor-pointer opacity-75 hover:opacity-100">
                                        <span>Template {template_name}.xlsx</span>
                                        <img src={iconLink} alt="" className="w-[12px] h-[16px] absolute top-0 bottom-0 right-[12px] my-auto" />
                                    </div>
                                </div>
                                <div className="flex justify-between items-center mb-[20px]">
                                    <p className="text-white text-[18px] ff-cp--bold">Subir archivo nuevo</p>
    
                                    <div className="w-[310px] flex flex-start items-center">
                                        <div className="relative bg-[#073147] w-[105px] h-[32px] mr-[12px] grid place-content-center ff-cp--bold text-[12px] text-[#07DACB] border-[1.5px] border-[#07DACB] rounded-[10px] text-center cursor-pointer opacity-75 hover:opacity-100">
                                            <span>Subir archivo</span>
                                            {
                                                !reset &&
                                                <input className="bg-transparent absolute inset-0 opacity-0 cursor-pointer"
                                                    onChange={(event) => handleFileChange(event)}
                                                    type="file" name="file">
                                                </input>
                                            }
                                        </div>
                                        <p className="ff-cp--medium text-[12px] w-[114px] text-white leading-none overflow-hidden"
                                            style={{ display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: 3 }}>
                                            {fileTemplateName}
                                        </p>
                                    </div>
                                </div>
                                <div className="flex justify-end">
                                    <button type="submit" className="ff-cp--bold text-[14px] text-[#ffffff] w-[167px] h-[36px] bg-cover bg-no-repeat bg-center opacity-80 hover:opacity-100 hover:scale-110" style={{ backgroundImage: `url(${btnExport})` }}>Actualizar</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ImportSection;