// Components
import DashboardSidebar from '../../components/dashboard/DashboardSidebar'

// Methods
import { getRole, getUser } from '../../common/methods/storage'

// Images
import iconLogo from '../../assets/images/icons/icon-logo.svg'
// Constnats
import ImportSection from '../../components/shared/ImportSection';

const ImportSuperadmin = () => {

    const user = getUser()
    const role = getRole()

    return (
        <section id="dashboard">
            <div className="h-screen w-screen overflow-hidden">
                <div className="h-full w-full p-[24px] grid grid-cols-[44px_auto] grid-rows-1 gap-[24px]">
                    <div className="col-[1_/_2] row-1">
                        <DashboardSidebar current={2} role={role} />
                    </div>
                    <div className="col-[2_/_2] min-h-full min-w-full row-1 gap-6 h-full grid grid-rows-[71px_auto] grid-cols-2">
                        <div className="col-span-1 row-[1_/_2] min-w-full">
                            <div className="relative h-full border-[#60DFFF] rounded-[20px] bg-gradient-to-b from-[#051d3e] border-2">
                                <span className="absolute top-[-16px] left-[24px] w-[160px] h-[32px] bg-[#07DBCB] text-[14px] text-[#000000] rounded-[20px] p-[6px] pl-[14px] leading-normal ff-cp--bold">Administrador</span>
                                <div className="h-[64px] pl-[38px] pr-[24px] pt-[16px] pb-[9px] flex justify-between items-center rounded-t-[20px] bg-gradient-to-b from-[#043658] to-transparent">
                                    <h2 className="ff-cp--bold text-[#ffffff] text-[24px]">M.O.V.E Match</h2>
                                    <div className="flex items-center">
                                        <h3 className="ff-cp--bold text-[16px] text-[#07DACB] leading-none mr-[32px]">{user?.nickname}</h3>
                                        <img src={iconLogo} alt="" className="w-[34px] h-[34px]" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-2 row-span-3 grid grid-cols-2 gap-6 min-w-full overflow-auto pt-4">

                            <ImportSection title="INSTITUCIONES" description="Aquí se sube la información sobre los INSTITUCIONES dentro de cada carrera." template="institutions" upload="institutions" template_name="Instituciones"></ImportSection>

                            <ImportSection title="CURSOS" description="Aquí se sube la información sobre las CURSOS dentro de buscador." template="courses" upload="courses" template_name="Cursos"></ImportSection>

                            <ImportSection title="CARRERAS" description="Aquí se sube la información sobre las CARRERA dentro de buscador." template="careers" upload="careers" template_name="Carreras"></ImportSection>

                            <ImportSection title="INSTITUCIONES-CARRERAS" description="Aquí se sube la información sobre las relaciones de las CARRERAS con las INSTITUCIONES dentro de buscador." template="institution_careers" upload="institution_careers" template_name="Instituciones-Carreras"></ImportSection>

                            <ImportSection title="MALLAS" description="Aquí se sube la información sobre las MALLAS dentro de buscador." template="course_meshs" upload="course_meshs" template_name="Mallas"></ImportSection>

                            <ImportSection title="CARRERAS - PESOS" description="Aquí se sube la información sobre las CARRERAS - PESOS dentro de buscador" template="career_features" upload="career_features" template_name="Carreras Pesos"></ImportSection>

                            <ImportSection title="CARACTERÍSTICAS" description="Aquí se sube la información sobre las CARACTERÍSTICAS dentro de buscador." template="features" upload="features" template_name="Características"></ImportSection>

                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ImportSuperadmin;