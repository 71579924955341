import * as React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// Services
import { authLogin } from '../../../services/apis/auth';

// Components
import PageTitle from '../../../components/shared/PageTitle';
import ErrorMessage from '../../../components/shared/ErrorMessage';
import ButtonRedirect from '../../../components/shared/ButtonRedirect';

// Models
import { MessageResult } from '../../../models/MessageResult';

// Constants
import { MESSAGES } from '../../../constants/response_message';

// Images
import btnBack from '../../../assets/images/icons/icon-back.svg'
import iconLock from '../../../assets/images/icons/icon-lock.svg'
import iconEmail from '../../../assets/images/icons/icon-email.svg'

const RecoveryChangeSuccess = () => {

  const navigate = useNavigate()
  const location = useLocation()
  
  const [message, setMessage] = React.useState(new MessageResult(false, false, ''))

  const validateSchema = Yup.object().shape({
    email: Yup.string().email(MESSAGES.REGISTER.WRONG.EMAIL.PATH).required(MESSAGES.REGISTER.WRONG.EMPTY),
    password: Yup.string().required(MESSAGES.REGISTER.WRONG.EMPTY)
  })

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      email: location.state.email,
      password: location.state.password
    },
    validationSchema: validateSchema,
    onSubmit: async (values) => {
      await authLogin(values).then(data => {
        if (data.status) navigate(data.page)
        else setMessage({ isView: true, status: data.status, message: data.message })
      }).catch(err => console.log(err))
    },
    validate: values => {
      validateSchema.validate(values).catch( err => {
        const error: string = err.errors[0];
        setMessage({isView: true, status: false, message: error});
      })
    }
  });

  return (
    <section className="recovery-page">
      <div className="grid grid-cols-5 grid-rows-1 w-screen h-screen">
        <ButtonRedirect text="return" linkReturn="/login" action="return"></ButtonRedirect>
        <PageTitle 
          title={{ text: "¡Contraseña restaurada!", color: "#60DFFF"} }
          subtitle={{text:"Click en ingresar para entrar mágicamente.", size:"text-[16px]"}}
          useLink={true}
          link={<div className="mt-3"><Link to="/login" className="text-[16px] text-[#7F29FE] flex gap-2">
                <img src={btnBack} alt="" className="w-[16px] h-[16px] mt-1" /> Regresar al log in</Link></div>}/>
          <div className="grid col-span-3 self-center place-items-center">
            <form onSubmit={formik.handleSubmit} className="form-signin w-100 grid self-center items-center"> 
              <div className="form-floating mb-4">
                <div className="grid mb-4">
                  <label className="ff-m--regular text-[16px] text-[#60DFFF] mb-1" htmlFor="email">Correo electrónico</label>
                  <div className="relative">
                    <span className="absolute block top-0 bottom-0 left-[13px] m-auto w-[22px] h-[22px] pointer-events-none bg-cover bg-center" style={{ backgroundImage: `url(${iconEmail})` }}></span>
                    <input
                      id="email"
                      name="email"
                      type="email"
                      className="w-full bg-transparent border border-solid border-[#FFFFFF] color-white rounded-xl leading-10 pl-[45px]"
                      onChange={formik.handleChange}
                      onPaste={e => e.preventDefault()}
                      value={formik.values.email}
                      placeholder="abc@gmail.com"
                    />
                  </div>
                </div>
                <div className="grid mb-4">
                  <label className="ff-m--regular text-[16px]  text-[#60DFFF] mb-1" htmlFor="repeat_password">Contraseña</label>
                  <div className="relative">
                    <span className="absolute block top-0 bottom-0 left-[13px] m-auto w-[22px] h-[22px] pointer-events-none bg-cover bg-center" style={{ backgroundImage: `url(${iconLock})` }}></span>
                    <input 
                        id="repeat_password"
                        name="repeat_password"
                        type="password"
                        className="w-full bg-transparent border border-solid border-[#FFFFFF] color-white rounded-xl leading-10 pl-[45px]"
                        onChange={formik.handleChange}
                        onPaste={e => e.preventDefault()}
                        value={formik.values.password}
                        placeholder="********"
                    />
                  </div>
                </div>
                <div className="mt-5">
                  {message.isView && !message.status && <ErrorMessage title={message.message}></ErrorMessage>}
                </div>                    
              </div>
              <ButtonRedirect type="submit" text="Ingresar" next={true}></ButtonRedirect>
            </form>
          </div>
      </div>
    </section>
  );
}

export default RecoveryChangeSuccess;