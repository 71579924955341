import * as React from 'react';
import { useFormik } from 'formik';
import { Link, useParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

// Services
import { resetPassword } from '../../../services/apis/auth';

// Models
import { MessageResult } from '../../../models/MessageResult';

// Components
import PageTitle from '../../../components/shared/PageTitle';
import ErrorMessage from '../../../components/shared/ErrorMessage';
import ButtonRedirect from '../../../components/shared/ButtonRedirect';

// Constants
import { MESSAGES } from '../../../constants/response_message';

// Images
import btnBack from '../../../assets/images/icons/icon-back.svg'
import iconLock from '../../../assets/images/icons/icon-lock.svg'

const RecoveryChangePassword = () => {

  const navigate = useNavigate()
  
  const { token } = useParams();
  
  const [message, setMessage] = React.useState(new MessageResult(false, false, ''))

  const validateSchema = Yup.object().shape({
    password: Yup.string().required(MESSAGES.REGISTER.WRONG.EMPTY),
    repeat_password: Yup.string().required(MESSAGES.REGISTER.WRONG.EMPTY).oneOf([Yup.ref('password'), MESSAGES.REGISTER.WRONG.PASSWORD_MATCH])
  })

  const formik = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: validateSchema,
    initialValues: {
      password: '',
      repeat_password: '',
      token: ''
    },
    onSubmit: async (values) => {
      values.token = token!;
        await resetPassword(values).then(data => {
          if(data.status)  navigate('/recovery/change-success', { state: { email: data.data.email, password: values.password } })
          else if(data.message === MESSAGES.RECOVERY.PASSWORD.WRONG.EXPIRED) navigate('/recovery/link-expired')
          else setMessage({ isView: true, status: data.status, message: data.data.message })
        })
        .catch(err => console.log(err))
    },
    validate: values => {
      validateSchema.validate(values).catch( err => {
        const error: string = err.errors[0];
        setMessage({isView: true, status: false, message: error});
      })
    }

  });

  return (
    <section className="recovery-page">
      <div className="grid grid-cols-5 grid-rows-1 w-screen h-screen">
        <ButtonRedirect action="return" linkReturn="/recovery/reset-password" text="return" />
          <PageTitle 
            title={{text: "Nueva contraseña", color: "#60DFFF" }}
            subtitle={{text:"Para guiarte mejor, introduce tus datos.", size:"text-[16px]"}}
            useLink={true}
            link={<div className="mt-3"><Link to="/login" className="text-[16px] text-[#7F29FE] flex gap-2"> 
                  <img src={btnBack} alt="" className="w-[16px] h-[16px] mt-1" /> Regresar al log in</Link></div>}/>
        <div className="grid col-span-3 self-center place-items-center">
            <form onSubmit={formik.handleSubmit} className="form-signin w-100 grid self-center items-center">
              <div className="form-floating mb-4">
                <div className="grid mb-4">
                  <label className="ff-m--regular text-[16px]  text-[#60DFFF] mb-1" htmlFor="password">Nueva contraseña</label>
                    <div className="relative">
                      <span className="absolute block top-0 bottom-0 left-[13px] m-auto w-[22px] h-[22px] pointer-events-none bg-cover bg-center" style={{ backgroundImage: `url(${iconLock})` }}></span>
                        <input 
                            id="password"
                            name="password"
                            type="password"
                            className="w-full bg-transparent border border-solid border-[#FFFFFF] color-white rounded-xl leading-10 pl-[45px]"
                            onChange={formik.handleChange}
                            onPaste={e => e.preventDefault()}
                            value={formik.values.password}
                            placeholder="Password"
                        />
                    </div>
                </div>
                <div className="grid mb-4">
                  <label className="ff-m--regular text-[16px]  text-[#60DFFF] mb-1" htmlFor="repeat_password">Repite tu contraseña</label>
                    <div className="relative">
                      <span className="absolute block top-0 bottom-0 left-[13px] m-auto w-[22px] h-[22px] pointer-events-none bg-cover bg-center" style={{ backgroundImage: `url(${iconLock})` }}></span>
                      <input 
                          id="repeat_password"
                          name="repeat_password"
                          type="password"
                          className="w-full bg-transparent border border-solid border-[#FFFFFF] color-white rounded-xl leading-10 pl-[45px]"
                          onChange={formik.handleChange}
                          onPaste={e => e.preventDefault()}
                          value={formik.values.repeat_password}
                          placeholder="Repeat Password"
                      />
                    </div>
                </div>
                <div className="mt-5">
                  {message.isView && !message.status && <ErrorMessage title={message.message}></ErrorMessage>}
                </div>
              </div>
              <ButtonRedirect type="submit" text="Ingresar" next={true}></ButtonRedirect>
            </form>
        </div>
      </div>
    </section>
  );
}

export default RecoveryChangePassword;