
import { API_ROUTES } from './../../constants/api_routes';
import { HEADERS } from "../../constants/connection";

export const createAccessPass = async (accessPasses?: any) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.ACCESS_PASSES.CREATE}`, {
        method: 'POST',
        headers: {
        ...HEADERS,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        body: JSON.stringify(accessPasses),
    })
    .then((response) => response.json())
        .then(data => {
            return data;
        })
    .catch(error => {
        return false
    });
}

export const getAllAccessPassses = async () => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.ACCESS_PASSES.GET.ALL.ONLY}`, {
        method: 'GET',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
    })
    .then((response) => response.json())
        .then(data => {
            return data;
        })
    .catch(error => {
            return null
        });
}

export const getAccessPassByName = async (name: string) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.ACCESS_PASSES.GET.BY.NAME}${name}`, {
        method: 'GET',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
    })
    .then((response) => response.json())
        .then(data => {
            return data;
        })
    .catch(error => {
            return null
        });
}

export const getAccessPassById = async (id: string) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.ACCESS_PASSES.GET.BY.ID}${id}`, {
        method: 'GET',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
    })
    .then((response) => response.json())
        .then(data => {
            return data;
        })
    .catch(error => {
            return null
        });
}

export const getAllAccessPassByWorkspaceName = async (name: string, signal: AbortSignal) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.ACCESS_PASSES.GET.ALL.BY.WORKSPACE.NAME}${name}`, {
        method: 'GET',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        signal: signal
    })
    .then((response) => response.json())
        .then(data => {
            return data;
        })
    .catch(error => {
            return []
        });
}

export const getAccessPassByWorkspaceId = async (workspace_id: string, signal?: AbortSignal) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.ACCESS_PASSES.GET.ALL.BY.WORKSPACE.ID}${workspace_id}`, {
        method: 'GET',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        signal: signal
    })
    .then((response) => response.json())
        .then(data => {
            return data;
        })
    .catch(error => {
            return []
        });
}

export const getAllAccessPassByUserId = async (user_id: string) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.ACCESS_PASSES.GET.ALL.BY.USER}${user_id}`, {
        method: 'GET',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
    })
    .then((response) => response.json())
        .then(data => {
            if(data.status) return data.groups
            else return []
        })
    .catch(error => {
            return []
        });
}

export const updateAccessPass = async (accessPass: any) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.ACCESS_PASSES.UPDATE}${accessPass.id}`, {
        method: 'PATCH',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        body: JSON.stringify(accessPass)
    })
    .then((response) => response.json())
        .then(async data => {
            return data.status
        })
    .catch(error => {
            return false;
    });

}

export const deleteAccessPass = async (accessPass: any) => {

    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.ACCESS_PASSES.DELETE}${accessPass.id}`, {
        method: 'DELETE',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
    })
    .then((response) => response.json())
        .then(async data => {
            return data.status
        })
    .catch(error => {
            return false;
    });

}
