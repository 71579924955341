// Constants
import { HEADERS } from "../../constants/connection";

export const generalReport = async (data: any) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api/admin/report/general`, {
        method: 'POST',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        body: JSON.stringify(data),
        redirect: 'follow',
    }).then(response => {
        return response.blob();
    })
    .then(blob => {
        return blob;
    })
    .catch(error => {
            return false
    });
}

export const groupUserReport = async (data: any) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api/admin/report/groupUser`, {
        method: 'POST',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        body: JSON.stringify(data),
        redirect: 'follow',
    }).then(response => {
        return response.blob();
    })
    .then(blob => {
        return blob;
    })
    .catch(error => {
            return false
    });
}

export const groupTriedReport = async (data: any) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api/admin/report/groupTried`, {
        method: 'POST',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        body: JSON.stringify(data),
        redirect: 'follow',
    }).then(response => {
        return response.blob();
    })
    .then(blob => {
        return blob;
    })
    .catch(error => {
            return false
    });
}

export const downloadTemplate = async (type: string) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api/admin/template/${type}`, {
        method: 'POST',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        redirect: 'follow',
    }).then(response => {
        return response.blob();
    })
    .then(blob => {
        return blob;
    })
    .catch(error => {
            return false
    });
}

export const saveTemplate = async (type: string, file: any) => {
    console.log({file})
    const body = {
        file
    }
    return await fetch(`${process.env.REACT_APP_API_URL}/api/admin/save/${type}`, {
        method: 'POST',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        body: JSON.stringify(body)
    }).then((response) => response.json())
    .then(data => {
        return data;
    })
    .catch(error => {
            return { status: false, error }
    });
}