export const roles = [
    {
        id: '995c3173-9861-49b0-94c6-30816aba97af',
        name: 'SUPERADMIN',
        title: 'Permisos de Super Admin de M.O.V.E'
    },
    {
        id: '73fdd87c-7c41-11ed-a1eb-0242ac120002',
        name: 'ADMIN',
        title: 'Permisos de Admin de M.O.V.E'
    },
    {
        id: 'bfbb9e9f-e630-4bdb-8d36-811a15a82d4e',
        name: 'ADMIN_CLIENT',
        title: 'Permisos de Admin Cliente'
    }
]

export const ADMIN_ROLES = {
    SUPERADMIN: 'SUPERADMIN',
    ADMIN: 'ADMIN',
    ADMIN_CLIENT: 'ADMIN_CLIENT',
}