
import { Link } from 'react-router-dom';
import btnPrimary from '../../assets/images/buttons/btn-primary.svg'
import btnReturn from '../../assets/images/buttons/btn-return.svg'
import iconLoading from '../../assets/images/icons/icon-spinner.svg'
import iconRight from '../../assets/images/icons/icon-right.svg'
import btnNext from '../../assets/images/buttons/btn-next.svg'

interface buttonProps {
  action?: string,
  state?: any,
  text: string,
  size?: string,
  type?: string,
  loading?: boolean,
  next?: boolean,
  linkReturn?: string
  callback?: () => void
}

const ButtonRedirect = ({ action = "callback", text, state, size, type, next, loading = false, linkReturn, callback}: buttonProps) => {

  return (
    action === "return" ?
      <Link to={linkReturn ?? '/'} state={state} className="absolute top-[10px] left-[10px] text-[0px] bg-cover w-[46px] h-[46px] transition ease opacity-60 hover:opacity-100" style={{ backgroundImage: `url(${btnReturn})` }}>Return</Link>
    :
    (
      action === "next" ?
        <Link onClick={callback}  to={linkReturn ?? '/'} state={{data: state}} className="absolute bottom-[25px] right-[30px] z-50 text-[0px] bg-contain bg-no-repeat w-[100px] h-[80px] transition ease opacity-100 hover:scale-110" style={{ backgroundImage: `url(${btnNext})` }}>Next</Link>
      :
        <button 
          onClick={callback} 
          disabled={ loading }
          type={type ? 'submit' : 'button'} 
          className={`relative grid grid-cols-12 place-content-center text-[150%] leading-[120%] ff-cp--bold text-[#d08dfc] bg-cover ${size ?? 'w-[313px]  h-[80px]'} mb-4 opacity-60 transition ease-in hover:opacity-100 hover:text-[#FEDFFF]`} 
          style={{ backgroundImage: `url(${btnPrimary})`, letterSpacing: '0.7px' }}
        >
          <span className="grid col-start-3 col-end-11">{text}</span>
          { next && (<img src={loading ? iconLoading : iconRight } alt="" className={"absolute top-[4px] bottom-0 right-[70px] m-auto h-auto" + (loading ? ' w-[25px]' : ' w-[10px]')}/>)}
        </button>
    )
  )
}

export default ButtonRedirect;