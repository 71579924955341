import { Route, Routes, Navigate } from 'react-router-dom';
import { Provider } from "react-redux";

// Serices
import store from "../services/auth";

// Components
import Layout from '../layout/layout';
import ProtectedRoute from '../components/auth/protect-route';

// Pages
import Login from '../pages/auth/Login';
import RecoverySendEmail from '../pages/auth/Recovery/RecoverySendEmail';
import RecoveryChangePassword from '../pages/auth/Recovery/RecoveryChangePassword';
import RecoveryLinkExpired from '../pages/auth/Recovery/RecoveryLinkExpired';
import RecoveryResetPassword from '../pages/auth/Recovery/RecoveryResetPassword';
import RecoveryChangeSuccess from '../pages/auth/Recovery/RecoveryChangeSuccess';
import DashboardAdmin from '../pages/admin/Dashboard';
import DashboardSuperadmin from '../pages/superadmin/Dashboard';
import AccessSuperadmin from '../pages/superadmin/Access';
import ImportSuperadmin from '../pages/superadmin/Import';
import FormulaSuperadmin from '../pages/superadmin/Formula';
import LinkDashboard from '../pages/admin/Link';

const App = () => {

  return (
    <Provider store={store}>
      <Layout>
        <Routes>
          <Route path="/"
            element={<Navigate to="/login" replace />}>
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/recovery/reset-password" element={<RecoveryResetPassword />} />
          <Route path="/recovery/send-message" element={<RecoverySendEmail />} />
          <Route path="/recovery/change-password/:token" element={<RecoveryChangePassword />} />
          <Route path="/recovery/link-expired" element={<RecoveryLinkExpired />} />
          <Route path="/recovery/change-success" element={<RecoveryChangeSuccess />} />
          <Route path='/' element={<ProtectedRoute />}>
            <Route path="/admin/dashboard" element={<DashboardAdmin />} />
            <Route path="/admin/link" element={<LinkDashboard />} />
            <Route path="/superadmin/dashboard" element={<DashboardSuperadmin />} /> 
            <Route path="/superadmin/access" element={<AccessSuperadmin />} />
            <Route path="/superadmin/import" element={<ImportSuperadmin />} />
            <Route path="/superadmin/formula" element={<FormulaSuperadmin />}></Route>
          </Route>
          <Route
            path="*"
            element={<Navigate to="/" replace />}
          />
        </Routes>
      </Layout>
    </Provider>
  );
}

export default App;