
import { API_ROUTES } from '../../constants/api_routes';
import { HEADERS } from "../../constants/connection";

export const createAccessPassUser = async (accessPassesUser?: any) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.ACCESS_PASSES_USER.CREATE}`, {
        method: 'POST',
        headers: {
        ...HEADERS,
        Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        body: JSON.stringify(accessPassesUser),
    })
    .then((response) => response.json())
        .then(data => {
            return data;
        })
    .catch(error => {
        return false
    });
}

export const getAccessPassUserById = async (id: string) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.ACCESS_PASSES_USER.GET.BY.ID}${id}`, {
        method: 'GET',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
    })
    .then((response) => response.json())
        .then(data => {
            return data;
        })
    .catch(error => {
            return null
        });
}

export const getAccessPassByAccessPassesId = async (workspace_id: string) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.ACCESS_PASSES_USER.GET.ALL.BY.ACCESS_PASSES}${workspace_id}`, {
        method: 'GET',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
    })
    .then((response) => response.json())
        .then(data => {
            return data;
        })
    .catch(error => {
            return []
        });
}

export const updateAccessPass = async (accessPass: any) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.ACCESS_PASSES_USER.UPDATE}${accessPass.id}`, {
        method: 'PATCH',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        body: JSON.stringify(accessPass)
    })
    .then((response) => response.json())
        .then(async data => {
            return data.status
        })
    .catch(error => {
            return false;
    });

}

export const updateAccessPassUserList = async (data: any) => {
    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.ACCESS_PASSES_USER.UPDATE_LIST}`, {
        method: 'POST',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        },
        body: JSON.stringify(data)
    })
    .then((response) => response.json())
        .then(async data => {
            return data
        })
    .catch(error => {
            return false;
    });

}

export const deleteAccessPass = async (accessPass: any) => {

    return await fetch(`${process.env.REACT_APP_API_URL}/api${API_ROUTES.ACCESS_PASSES_USER.DELETE}${accessPass.id}`, {
        method: 'DELETE',
        headers: {
            ...HEADERS,
            Authorization: `Bearer ${localStorage.getItem("access_token")}`
        }
    })
    .then((response) => response.json())
        .then(async data => {
            return data.status
        })
    .catch(error => {
            return false;
    });

}
