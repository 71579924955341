
import error from '../../assets/images/icons/icon-error.svg'

interface messageProps {
  title: String
}

const ErrorMessage = ({title}: messageProps) => {
  return (
    <div className="grid grid-cols-12 grid-rows-1">
        <div className="col-span-1">
          <img src={error} alt="" className="w-[25px] h-[25px]" />
        </div>
        <div className="col-span-11 ml-3">
          <h2 className="font-medium text-[18px] text-[#FD0130] justify-self-center mb-2 leading-none"><span className="italic mr-2">{'//'}</span>{'ERROR'}</h2>
          <h2 className="font-medium text-[18px] text-[#FD0130] justify-self-center leading-none">{title}</h2>
        </div>
    </div>
  );
}

export default ErrorMessage;