// Components
import DashboardSidebar from '../../components/dashboard/DashboardSidebar'

// Methods
import { getRole, getUser } from '../../common/methods/storage'

// Images
import iconLogo from '../../assets/images/icons/icon-logo.svg'

// Constnats
import iconLink from '../../assets/images/icons/icon-link.svg'
import iconLoading from '../../assets/images/icons/icon-spinner.svg'
import btnExport from '../../assets/images/buttons/btn-export.svg'
import { useEffect, useState } from 'react'
import { getAccessPassByWorkspaceId } from '../../services/apis/access_pass'
import moment from 'moment'

const LinkDashboard = () => {

    const [loading, setLoading] = useState<boolean>(true)
    const [links, setLinks] = useState([])

    const user = getUser()
    const role = getRole()

    const getGroupsByWorkspaceId = async (id: string) => {
        await getAccessPassByWorkspaceId(id).then((data) => {
            setLinks(data.groups)
            setLoading(false)
        });
    };

    useEffect(() => {
        getGroupsByWorkspaceId(user.workspace_id)
    }, [])

    return (
        <section id="dashboard">
            <div className="h-screen w-screen overflow-hidden">
                <div className="h-full w-full p-[24px] grid grid-cols-[44px_auto] grid-rows-1 gap-[24px]">
                    <div className="col-[1_/_2] row-1">
                        <DashboardSidebar current={2} role={role} />
                    </div>
                    <div className="col-[2_/_2] min-h-full min-w-full row-1 gap-6 h-full grid grid-rows-[71px_auto] grid-cols-2">
                        <div className="col-span-1 row-[1_/_2] min-w-full">
                            <div className="relative h-full border-[#60DFFF] rounded-[20px] bg-gradient-to-b from-[#051d3e] border-2">
                                <span className="absolute top-[-16px] left-[24px] w-[160px] h-[32px] bg-[#07DBCB] text-[14px] text-[#000000] rounded-[20px] p-[6px] pl-[14px] leading-normal ff-cp--bold">Administrador</span>
                                <div className="h-[64px] pl-[38px] pr-[24px] pt-[16px] pb-[9px] flex justify-between items-center rounded-t-[20px] bg-gradient-to-b from-[#043658] to-transparent">
                                    <h2 className="ff-cp--bold text-[#ffffff] text-[24px]">M.O.V.E Match</h2>
                                    <div className="flex items-center">
                                        <h3 className="ff-cp--bold text-[16px] text-[#07DACB] leading-none mr-[32px]">{user?.nickname}</h3>
                                        <img src={iconLogo} alt="" className="w-[34px] h-[34px]" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-2 row-span-3 grid grid-cols-2 gap-6 min-w-full overflow-auto pt-4">
                            {
                                loading &&
                                <div className="fixed bg-black/60 inset-0 grid place-content-center z-[100]">
                                    <img src={iconLoading} width={80} height={80}></img>
                                </div>
                            }
                            {
                                !loading && links?.length > 0 && (
                                    <div className="col-span-2 row-span-2 min-w-full h-full">
                                        <div className="h-full relative border-[#60DFFF] border-2 rounded-[20px]">
                                            <div className="relative h-full rounded-[20px]">
                                                <span className="absolute top-[-16px] left-[25px] w-[216px] h-[32px] bg-[#07DBCB] text-[14px] text-[#000000] rounded-[20px] p-[6px] pl-[14px] leading-normal ff-cp--bold z-20">Links</span>
                                                <div className="overflow-hidden rounded-[20px] h-full pt-[43px] px-[30px] pb-[24px] bg-gradient-to-b from-[#051d3e]">

                                                    <p className="text-[16px] text-white leading-none mb-[40px]">Descripción de la sección</p>

                                                    <div className="grid grid-cols-5">
                                                        <div className="ff-cp--bold p-[20px] col-span-1 border rounded-tl-[25px] text-center text-[20px] text-white border-white p-2">Fecha</div>
                                                        <div className="ff-cp--bold p-[20px] col-span-2 border text-[20px] text-center text-white border-white p-2">Nombre de Grupo / Campaña</div>
                                                        <div className="ff-cp--bold p-[20px] col-span-2 border text-[20px] text-center text-white border-white p-2">Link</div>
                                                    </div>

                                                    {links?.map((item: any) => (
                                                        <div className="grid grid-cols-5 bg-[#070625]">
                                                            <div className="px-[20px] py-[10px] text-white col-span-1 border border-white p-2">{ moment(item.created_at).format("YYYY/MM/DD") }</div>
                                                            <div className="px-[20px] py-[10px] text-white col-span-2 border border-white p-2">{ item.name }</div>
                                                            <div className="px-[20px] py-[10px] text-[#F408B2] col-span-2 border border-white p-2"><a href={ process.env.REACT_APP_WEB_URL + user.workspace.domain + '/' + encodeURI(item.name) } target="_blank">{ process.env.REACT_APP_WEB_URL + user.workspace.domain + '/' + encodeURI(item.name) }</a></div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default LinkDashboard;