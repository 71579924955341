export class MessageResult {

    isView: boolean
    status: boolean
    message: string
    razon?: string

    constructor(isView: boolean, status: boolean, message: string, razon?: string) {
        this.isView = isView;
        this.status = status;
        this.message = message;
        this.razon = razon;
    }

}