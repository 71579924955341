import { Toaster } from 'react-hot-toast';
//import Footer from './shared/Footer';
 
const Layout = ({ children }: { children: any}) => {
 
     return (
         <>
            {/* <Header /> */}
                <main className="main">
                    {children}
                </main>
                <Toaster 
                  position="top-right"
                  reverseOrder={false}
                />
            {/* <Footer/> */}
         </>
     );
};
 
export default Layout;
 