export const MESSAGES = {
    'REGISTER': {
        'SUCCESS': 'El usuario ha sido registrado correctamente',
        'WRONG': {
            'PASSWORD_MATCH': 'Las contraseñas deben coincidir',
            'FAILED': 'El usuario no ha sido registrado',
            'EMPTY': 'Debe llenar todos los campos',            
            'EMAIL': {
                'PATH': 'Debe ingresar un correo electrónico válido'
            },
            'TERMS': 'Para continuar debe aceptar los términos y condiciones'
        },
    },
    'RECOVERY': {
        'PASSWORD': {
            'WRONG': {
                'EMPTY': 'Debe ingresar el correo',    
                'EXPIRED': 'jwt expired'
            }            
        }
    },
    'ACCESSPASS' : {
        'CREATE': {
            'WRONG': {
                'EMPTY': 'Debe ingresar el nombre'
            }
        }
    },
    'UPLOAD': {
        'WRONG': {
            'EMPY': 'Debe agregar algún archivo.'
        }
    }
}